@media (min-width: 769px) {

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding:0;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #ffffff;
  
}

}

@media (max-width: 768px) {
  /* CSS styles for phones (screen width up to 768px) */
  /* Add your phone-specific styles here */
}