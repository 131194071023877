.conact-me{
  height: 100vh; /* Set container height to viewport height */
  overflow-y: auto; /* Enable vertical scroll */
}

.contact-page-10001 {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  width: 95vw;

}


.get-social-container-10001 {
  flex: 0.7;
  background-color: black;
  border: 2px solid #333232;
  border-radius: 25px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 50px;
    padding-left: 30px;
    padding-right: 30px;
  height: 350px;

}

.send-message-container-10001 {
  flex: 2.3;
  background-color: black;
  border: 2px solid #333232;
  border-radius: 25px;
  padding: 50px;

}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.get-social-container-10001,
.send-message-container-10001 {
  animation: fadeIn 1s ease-in-out;
}

.social-row-10001 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: -15px;
}

.social-button-10001 {
  width: 200px;
  cursor: pointer;
  position: relative;
  background-color: black;
  border: 2px solid #333232;
  border-radius: 25px;
  padding-left: 10px;
  height: 60px;
  margin-left: 10px;
}
.get-social-container-10001 p {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
.send-message-container-10001 p {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.button-icon {
  font-size: 40px;
  margin-top: 5.5px;
  transition: all 0.3s ease;
}

.button-text {
  position: absolute;
  top: 50%;
  left: 60px; /* Adjust the right position */
  transform:  translateY(-50%);
  transition: all 0.3s ease;
}

.button-text span {
  display: block;
}

.button-text strong {
  display: block;
  font-weight: normal;
  font-size: 16px;
  opacity: 1;
  transition: all 0.3s ease;
}

.button-icon.hovered {
  transform: scale(1.2);
  transform: translatex(10%) scale(1.2);
}


.social-button-10001:hover .button-text {
  transform: translatex(20%) translate(0%, -80%) scale(1.2);
}

.social-button-10001:hover .button-text span {
  opacity: 0;
}

.tags-container-10001 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

}

.tag-10001 {
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: normal;
  background-color: black;
  border: 2px solid #333232;
  border-radius: 25px;
  padding: 5px;
  
}

.tag-10001:hover {
  transform: scale(1.1); /* Example: Increase size on hover */
}


.input-container-10001 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  transition: all 0.3s ease;

}

.input-container-10001 input {
  width: 48%;
  min-width: 100px;
  padding: 10px;
  margin-bottom: 10px;
  background-color:rgb(248, 242, 238);
  border: 2px solid #333232;
  border-radius: 5px;
  font-size: 15px;

}

.message-container-10001 textarea {
  width: 100%;
  min-width: 440px;
  height: 200px;
  padding: 10px;
  background-color: rgb(248, 242, 238);

  margin-bottom: 20px;
  border: 2px solid #333232;
  border-radius: 5px;
  font-size: 20x;

}

.button-container-10001 {
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease;

}

.button-container-10001 button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: black;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  
  
  padding-top: 8px;
  padding-bottom: 8px;
  float: right;
  height: 35px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  position: relative;
  transition: background-color 0.3s; /* Smooth transition for color change */
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid #333232;
  cursor: pointer;
  border-radius: 5px;
}

.button-container-10001 button:hover {
  background-color: #332c2c;
}

.popup-10001 {
  
  left: 43%;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease forwards;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.9);
  padding: 10px; /* Increase padding */
  padding-top: 40px;
  border-radius: 20px; /* Increase border radius */
  border: 2px solid #333232;
  z-index: 2;
  max-width: 100%; /* Increase max-width */
  max-height: 100%; /* Increase max-height */
  overflow: auto; /* Add overflow to enable scrolling */
}

.popup-10001 p {
  margin-bottom: 10px;
}

.popup-10001 a {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease; /* Add transition for the color property */
}

.popup-10001 a:hover {
  color: rgb(49, 243, 1);
}

.popup-10001 button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: rgb(37, 33, 33);
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  
  
  padding-top: 8px;
  padding-bottom: 8px;
  left:40%;
  height: 35px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  transition: background-color 0.3s; /* Smooth transition for color change */
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid #333232;
  cursor: pointer;
  border-radius: 5px;
}

.popup-10001 button:hover {
  background-color: #332c2c;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 1180px) {
  .social-button-10001 {
    width: 180px;

  }

  .button-text span {
    font-size: 15px;
  }
  .button-text strong {
    font-size: 15px;
    word-spacing: 0.01px; /* Adjust the value as needed */
  }
}

@media screen and (max-width: 1180px) {
  .get-social-container-10001 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .social-button-10001 {
    width: 150px;
  }

  .button-text span {
    font-size: 12px;
  }
  .button-text strong {
    font-size: 11px;
  }
  .social-button-10001:hover .button-text {
    transform: translatex(10%) translate(0%, -80%) scale(1.2);
  }
}

@media screen and (max-width: 1080px) {

  .social-button-10001 {
    width: 130px;
    
  }
  .button-text {
    left: 50px;
  }
  .button-text span {
    font-size: 11px;
    
  }
  .button-text strong {
    font-size: 11px;
  }
  .button-icon {
    font-size: 35px;
    margin-top: 10px;
    transition: all 0.3s ease;
  }
  .social-button-10001:hover .button-text {
    transform: translatex(5%) translate(0%, -80%) scale(1.1);
  }
}


@media screen and (max-width: 1080px) {

  .get-social-container-10001 {
    margin-left: 10px;
    margin-right: 10px;

  }



}


@media screen and (max-width: 884px) {

  .social-row-10001 {
    display: flex;
    flex-direction: column; /* Stack items on top of each other */
    margin-bottom: 0px;
   
  }
  .get-social-container-10001{
    height: 600px;
  }
.social-button-10001{
  margin-top: 10px;
}
.get-social-container-10001 p {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}


.popup-10001 {
  
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease forwards;
}

}



@media screen and (max-width: 769px) {
  .contact-page-10001{
    margin-top: 20px;
  }
  .conact-me
  {
    color: white;
  }

}


@media screen and (max-width: 742px) {
  .input-container-10001 input {
    width: 48%;
    min-width: 75px;
    padding: 10px;
    margin-bottom: 10px;
    background-color:rgb(248, 242, 238);
    border: 2px solid #333232;
    border-radius: 5px;
    font-size: 15px;
  
  }
  
  .message-container-10001 textarea {
    width: 100%;
    min-width: 390px;
    height: 200px;
    padding: 10px;
    background-color: rgb(248, 242, 238);
  
    margin-bottom: 20px;
    border: 2px solid #333232;
    border-radius: 5px;
    font-size: 20x;
  
  }
  

}
@media screen and (max-width: 688px) {
  .popup-10001 {
  left: 27%;
  transform: translate(-50%, -40%);
  }

  
  .contact-page-10001 {
    flex-direction: column;
    align-items: center; /* Align components in the center when stacked */
    margin-left: 10px;
    width: 95vw;
  }

  .get-social-container-10001,
  .send-message-container-10001 {
    width: 100%; /* Take full width when stacked */
    margin: 20px 0; /* Add spacing between components */
  }
  .social-row-10001 {
    display: flex;
    flex-direction: row; /* Stack items on top of each other */
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: -15px;
  }
  .social-button-10001 {
    width: 200px;
    cursor: pointer;
    position: relative;
    background-color: black;
    border: 2px solid #333232;
    border-radius: 25px;
    padding-left: 10px;
    height: 60px;
    margin-left: 10px;
  }
  .get-social-container-10001 p {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .send-message-container-10001 p {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .button-icon {
    font-size: 40px;
    margin-top: 5.5px;
    transition: all 0.3s ease;
  }
  
  .button-text {
    position: absolute;
    top: 50%;
    left: 60px; /* Adjust the right position */
    transform:  translateY(-50%);
    transition: all 0.3s ease;
  }
  
  .button-text span {
    display: block;
  }
  
  .button-text strong {
    display: block;
    font-weight: normal;
    font-size: 16px;
    opacity: 1;
    transition: all 0.3s ease;
  }
  .get-social-container-10001{
    padding: 10px;
  }
  .send-message-container-10001 {
    padding: 10px;
  
  }
  .input-container-10001 input {
    width: 48%;
    padding: 10px;
    margin-bottom: 10px;
    background-color:rgb(248, 242, 238);
    border: 2px solid #333232;
    border-radius: 5px;
    font-size: 15px;
  
  }
  
  .message-container-10001 textarea {
    width: 100%;
    padding: 10px;
    background-color: rgb(248, 242, 238);
    margin-bottom: 20px;
    border: 2px solid #333232;
    border-radius: 5px;
    font-size: 20x;
  
  }
  .popup-10001 {
    left: 20%;
    }
  
}

@media screen and (max-width: 438px) {
  .input-container-10001 {
    flex-direction: column; /* Stack items on top of each other */
  }

  .input-container-10001 input {
    width: 100%; /* Take full width */
  }

  .message-container-10001 textarea {
    width: 100%; /* Take full width */
    min-width: 0; /* Remove any minimum width constraint */

  }
  .popup-10001 {
    left: 17%;
    }
  
}

@media screen and (max-width: 400px) {
  .social-button-10001 {
    width: 150px;
  }

  .button-text span {
    font-size: 12px;
  }
  .button-text strong {
    font-size: 11px;
  }
  .social-button-10001:hover .button-text {
    transform: translatex(10%) translate(0%, -80%) scale(1.2);
  }
  .popup-10001 {
    left: 15%;
    }
  
}
 
@media screen and (max-width: 338px) {
  .popup-10001 {
    left: 5%;
    }
  .social-button-10001 {
    width: 130px;
    
  }
  .button-text {
    left: 50px;
  }
  .button-text span {
    font-size: 11px;
    
  }
  .button-text strong {
    font-size: 11px;
  }
  .button-icon {
    font-size: 35px;
    margin-top: 10px;
    transition: all 0.3s ease;
  }
  .social-button-10001:hover .button-text {
    transform: translatex(5%) translate(0%, -80%) scale(1.1);
  }
}

@media screen and (max-width: 296px) {
  .social-button-10001 {
    width: 130px;
    
  }
  .button-text {
    left: 50px;
  }
  .button-text span {
    font-size: 9px;
    
  }
  .button-text strong {
    font-size: 9px;
  }
  .button-icon {
    font-size: 35px;
    margin-top: 10px;
    transition: all 0.3s ease;
  }
  .social-button-10001:hover .button-text {
    transform: translatex(10%) translate(0%, -80%) scale(1.2);
  }
}