/* ReadmeContainer.css */

/* Container styles */
.readme-container-8002 {
    max-width: 800px;
    margin: 0 auto;
    background-color: transparent; /* Dark background color */
    color: #c9d1d9; /* Text color */
    padding: 20px; /* Add padding for spacing */
    font-size: 1rem; /* Base font size */
    line-height: 2; /* Double-spaced text */
  }
  /* Adjust image size */
.readme-container-8002 img {
    max-width: 100%; /* Make images responsive */
    height: auto;
  }
  
  /* Heading styles */
  .readme-container-8002 h1,
  .readme-container-8002 h2,
  .readme-container-8002 h3,
  .readme-container-8002 h4,
  .readme-container-8002 h5,
  .readme-container-8002 h6 {
    font-size: 1.5em; /* Adjust heading font size */
  }
  
  /* Link styles */
  .readme-container-8002 a {
    color: #58a6ff; /* Link color */
  }
  
  /* Horizontal rule styles */
  .readme-container-8002 hr {
    border: none;
    border-top: 1px solid #30363d; /* Horizontal line color */
    margin: 20px 0; /* Adjust spacing */
  }
  
  /* Code block styles */
  .readme-container-8002 pre {
    background-color: #161b22; /* Code block background color */
    padding: 10px; /* Add padding for code blocks */
    border-radius: 5px; /* Rounded corners for code blocks */
  }
  
  /* List styles */
  .readme-container-8002 ul,
  .readme-container-8002 ol {
    margin: 10px 0; /* Adjust spacing */
    font-size: 1rem; /* Base font size */

  }
  
  /* List item styles */
  .readme-container-8002 li {
    margin-bottom: 5px; /* Adjust spacing between list items */
    margin-left: 15px;
  }
  
  /* Inline code styles */
  .readme-container-8002 code {
    background-color: #161b22; /* Inline code background color */
    padding: 2px 5px; /* Add padding for inline code */
    border-radius: 3px; /* Rounded corners for inline code */
  }
  .readme-container-8002 .nested {
    padding-left: 20px; /* Adjust indentation */
  }

  .readme-width-8003{
    max-width: 90vw;
    background-color: transparent; /* Dark background color */
  }