.tools-container-6001 {
    display: flex;
    flex-direction: row; /* Arranges the inner containers side by side */
    align-items: flex-start; /* Aligns items at the start of the container */
    justify-content: space-around; /* Distributes space between and around content items */
    margin: 20px 0;
    margin-top: 3%;
    border: 2px solid rgb(39, 33, 33);
    background-color: transparent;
    border-radius: 10px;
    width: 95vw;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    padding: 20px;
}

.inner-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns items at the start of the column */
    flex-basis: 30%; /* Each container takes roughly one-third of the space */
    padding: 10px;
}

.half-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns items at the start of the column */
    flex-basis: 50%; /* Each half inside the middle container takes half of the space */
    padding: 10px;
}

.badge-container {
    display: flex;
    flex-wrap: wrap; /* Allows badges to wrap onto the next line */
    justify-content: flex-start; /* Aligns badges to the start */
    width: 100%; /* Ensures the container takes up full width of its parent */
}

  
  .logo-6001 {
    margin: 5px;
    width: auto; /* Keep width auto or set a specific size */
    height: 25px; /* Larger height for visibility */
    display: inline-block; /* Display as inline-block for inline arrangement */
    animation: drop 1s ease-out forwards;

  }

  @keyframes drop {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 900px) {
    .tools-container-6001 {
      flex-direction: column; /* Arranges the inner containers side by side */

    }

    .container-one {
      flex-direction: column;
    }
  
    .section {
      width: 100%; /* Full width when the screen is small */
    }

    .logo-800 {
        height: 15px; /* Slightly smaller on medium devices */
      }
      .inner-container h3{
        visibility: visible;

      }
  }
  @media (max-width: 800px) {

    .tools-container-6001 {
      width: 75vw; 
      margin-top: -6%;
    }
  }

  @media (max-width: 767px) {

      .inner-container h3{
        visibility: visible;
        color: #ffffff; 
      }
      .half-container h4{
        visibility: visible;
        color: #ffffff; 
      }
  }

  @media (max-width: 640px) {
    .tools-container-6001 {
      width: 65vw; /* Arranges the inner containers side by side */
      margin-top: -35%;
      margin-bottom: 40%;
    }
    .logo-6001 {
      margin: 5px;
      width: auto; /* Keep width auto or set a specific size */
      height: 18px; /* Larger height for visibility */
      display: inline-block; /* Display as inline-block for inline arrangement */
    }
  }

  /* Adjustments for smaller screens */
@media (max-width: 480px) {
    .logo-800 {
      height: 10px; /* Even smaller on small devices */
    }
    .tools-container-6001 {
      width: 65vw; /* Arranges the inner containers side by side */
      margin-top: -45%;
      margin-bottom: 40%;
    }
  }