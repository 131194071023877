
.cont {
    cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39, auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px; /* Adjust the height to your preference */
    position: relative;
    margin-bottom: 50px;
    margin-top: 600px;

  }
  
  .deck {
    position: absolute; /* Changed to absolute */
    width: 800px;
    height: 800px;
    will-change: transform;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: none;
    margin-top: -10vh;
    
  }
  
  .deck > div {
    background-color: white;
    background-size: auto 85%;
    background-repeat: no-repeat;
    background-position: center ;
    max-width: 100%; /* Adjust the maximum width to your preference */
    max-height: 100%; /* Adjust the maximum height to your preference */
    width: 800vw; /* Adjust the width to your preference */
    height: 600px; /* Adjust the height to your preference */
    will-change: transform;
    border-radius: 10px;
    box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
  }



  @media (max-width: 961px) {

    .deck > div {
      
      width: 600px; /* Adjust the width to your preference */
      height: 400px; /* Adjust the height to your preference */

    }
  }
  
  @media (max-width: 780px) {
    .deck{
      width: 600px;
      height: 500px;
    }

  }

  @media (max-width: 700px) {

    .deck > div {
      
      width: 500px; /* Adjust the width to your preference */
      height: 400px; /* Adjust the height to your preference */
      
    }
    .cont{
      margin-top: 550px;
    }
  }

  @media (max-width: 630px) {
    .deck > div {
      
      width: 400px; /* Adjust the width to your preference */
      height: 300px; /* Adjust the height to your preference */

    }
    .cont{
      margin-top: 360px;
    }

    .deck{
      width: 600px;
      height: 350px;
    }
  }


  @media (max-width: 479px) {

    .deck > div {
      
      width: 350px; /* Adjust the width to your preference */
      height: 250px; /* Adjust the height to your preference */

    }
    .cont{
      margin-top: 460px;
    }
  }
  
  @media (max-width: 455px) {

    .cont{
      margin-top: 500px;
    }
  }

  @media (max-width: 380px) {

    .deck > div {
      
      width: 300px; /* Adjust the width to your preference */
      height: 200px; /* Adjust the height to your preference */

    }
    
    .cont{
      margin-top: 460px;
    }

    .deck{
      width: 600px;
      height: 250px;
    }
  }


  @media (max-width: 330px) {

    .deck > div {
      
      width: 250px; /* Adjust the width to your preference */
      height: 150px; /* Adjust the height to your preference */

    }
  }