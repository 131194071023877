.container-602 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the boxes horizontally */
    align-items: flex-start; /* Align items to the start (top) of the container */
    margin-top: 60px;
}

  
  .box-602 {
    position: relative;
    width: 250px;
    height: 250px;
    margin: 20px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 20px;
    transition: transform 0.3s ease; /* Add transition for smooth scaling */

  }

  @media only screen and (max-width: 614px) {
    .box-602{
        width: 200px;
        height: 200px;
    }

  }

  @media only screen and (max-width: 509px) {
    .box-602{
        width: 170px;
        height: 170px;
    }

  }

  @media only screen and (max-width: 446px) {
    .box-602{
        width: 150px;
        height: 150px;
    }

  }

  @media only screen and (max-width: 404px) {
    .box-602{
        margin: 10px;
    }

  }

  @media only screen and (max-width: 362px) {
    .box-602{
        width: 120px;
        height: 120px;
    }

  }

  .box-602:hover {
    transform: scale(1.1); /* Scale the box on hover */
  }
  
  .box-602 img,
  .box-602 video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .box-overlay-602 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover; /* Ensure the background image covers the entire box */
    background-position: center; /* Center the background image within the box */
    transition: opacity 0.3s ease;
    opacity: 0.7;
  }
  
  
  .box-602:hover .box-overlay-602 {
    opacity: 0.7;
  }
  
  .box-content-602 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: white;
    text-align: center;
  }
  
  .box-content-602 span {
    display: block;
  }
  
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px; /* Increase padding */
    padding-top: 40px;
    border-radius: 20px; /* Increase border radius */
    z-index: 2;
    max-width: 100%; /* Increase max-width */
    max-height: 100%; /* Increase max-height */
    overflow: auto; /* Add overflow to enable scrolling */
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: white;
    font-size: 30px;
    transition: transform 0.3s ease; /* Add transition for smooth hover effect */
  }
  
  .close-button:hover {
    transform: scale(1.1); /* Scale the button on hover */
  }
  
  
  .popup img,
  .popup video,
  .popup iframe {
    max-width: 100vw;
    max-height: 100vh;
    width: 80vw;
    height: 60vh;
  }
  
  