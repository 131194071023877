@import url('https://fonts.googleapis.com/css2?Open+Sans&display=swap');
@media (min-width: 770px) {

  .starScreen .nav-container {
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    width: 100vw;
    font-family: 'Roboto', serif;
    color: #fff;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    border: 2px solid #000000; /* Add the border */
    font-weight: bold;
    z-index: 1000000;
  }
  .Main-page-1001.nav-container{
    background-color: #831818;
  }
  
  .nav-container {
    background-color: #8FB5F5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    font-family: monospace;
    color: #fff;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    border: 2px solid #5B5B5B; /* Add the border */
    font-weight: bolder;
  
  }

.nav-container .nav-menu {
  overflow: hidden; /* Add this to hide overflowing elements */
}

.nav-container .nav-links {
  width: 100%; /* Update the width to ensure the links stay within the container */
}

.nav-menu {
  display: flex;
  list-style: none;
  max-width: 40vw;
  text-align: center;
  margin-right: 0.5rem;
  position: relative;
  transition: 0.5s all ease-in-out;
  top:0px;
}

.starScreen .nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  width: 200px; /* Set a fixed width in pixels */
  max-width: 100%; /* Optionally, set a maximum width as a percentage of the parent */
  height: 100%;
  border-bottom: 3px solid transparent;
  flex-shrink: 0; /* Prevent items from shrinking */
}

.nav-links {
  color: #1868f1;
  text-decoration: none;
  padding: 0.5rem 1rem;
  width: 200px; /* Set a fixed width in pixels */
  max-width: 100%; /* Optionally, set a maximum width as a percentage of the parent */
  height: 100%;
  border-bottom: 3px solid transparent;
  flex-shrink: 0; /* Prevent items from shrinking */
}

.nav-links2 {
  color: #1868f1;
  padding: 1.29rem 1rem;
  width: 500px; /* Set a fixed width in pixels */
  max-width: 100%; /* Optionally, set a maximum width as a percentage of the parent */
  height: 100%;
  border-bottom: 6px solid transparent;
  flex-shrink: 0; /* Prevent items from shrinking */
}

.nav-menu .links {
  list-style: none;
  display: flex;
  color: #fff;
  cursor: pointer;
}

.nav .links li {
  margin: 0 15px;
  font-size: 14px;
  font-weight: 600;
  
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}
.linkItem {
  margin-top: 0.5rem ;
}
.nav-item .aboutme1 {
  margin-left:0.8rem ;
}

.nav-item:after {
  content: "";
  display: flex;
  height: 2px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
  
}

 .nav-item:hover:after {
  width: 100%;
  background: #1868f1;
}
.starScreen .nav-item:hover:after {
  width: 100%;
  background: white;
  
}

 .nav-item .active {
  color: #1868f1;
  border: 2px solid #1868f1;
}


.starScreen .nav-item .active {
  color: white;
  border: 2px solid white;
}

.nav-item  .LOGO{
  width: 40; 
  border: 10px solid #1868f1;
}
.starScreen .nav-item  .LOGO{
  width: 40;
  border: 10px solid white;
}

.nav-icon {
  display: none;
}



.wrapper{
  width: 40%;
  max-width: 1440px;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 auto;
}



  .navbar{
    position: relative;
    transition: 0.5s all ease-in-out;
  }

  .navbar.expanded{
    height: 100vh;
  }

  .navbar.expanded .links{
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }

  .navbar.expanded .links li{
    margin: 15px 0;
    font-size: 24px;
  }
}




.social-links-1001 {
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px;
  z-index: 1000; /* High z-index to keep it above other content */
}

.social-links-1001 a {
  color: #ffffff; /* Set the color of the icons, adjust if necessary */
  margin-right: 10px; /* Space between the icons */
  text-decoration: none; /* Remove underline from icons */
  font-size: 24px; /* Icon size, adjust as needed */
}

.social-links-1001 a:hover {
  color: #cccccc; /* Hover color effect for icons */
}

.social-links-1002 {
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px;
  z-index: 1000; /* High z-index to keep it above other content */
}

.social-links-1002 a {
  color: #ffffff; /* Set the color of the icons, adjust if necessary */
  margin-right: 10px; /* Space between the icons */
  text-decoration: none; /* Remove underline from icons */
  font-size: 30px; /* Icon size, adjust as needed */
}

.social-links-1002 a:hover {
  color: #cccccc; /* Hover color effect for icons */
}






@media (max-width: 769px) {
  .navbar{
    width: 100%;
    background-color: #000000;
    z-index: 100000000;
  }
 
}