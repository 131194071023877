

.details-8002 {
    height: 65%;
    width: 90vw;
    margin-right: 1%;
    margin-top: 1%;
    overflow-y: auto;
    font-size: 1rem; /* Base font size */
    color: #c9d1d9; /* Text color */
    line-height: 2; /* Double-spaced text */
}



@media only screen and (max-width: 1627px) {



.details-8002 {
    height: 77%;
    margin-right: 1%;
    margin-top: 1%;
    overflow-y: auto;
}


  
}


@media only screen and (max-width: 1095px) {
 
.details-8002 {
  height: 75%;
  width: 90vw;
  margin-right: 1%;
  margin-top: 1%;
  overflow-y: auto;
}
}

@media only screen and (max-width: 847px) {

      
.details-8002 {
  height: 65%;
  width: 80vw;
  margin-right: 1%;
  margin-top: 1%;
  overflow-y: auto;
}



}


@media only screen and (max-width: 523px)
{

}


@media only screen and (max-width: 390px) {
  .details-8002 {
    
    font-size: 90%;
    margin-bottom: 60px;

  }

}
@media only screen and (max-width: 280px) {


      
  
.details-8002 {
  font-size: 75%;
  height: 70%;
  width: 65vw;
  margin-right: 1%;
  margin-top: 1%;
  overflow-y: auto;
}
}



@media only screen and (max-height: 843px) {

  .details-8002 {
    height:  280px;
}

}
@media only screen and (max-height: 820px) {

  .details-8002 {
    height:  260px;
}

}
@media only screen and (max-height: 783px) {

  .details-8002 {
    height:  240px;
}

}
@media only screen and (max-height: 743px) {

  .details-8002 {
    height:  210px;
}

}
@media only screen and (max-height: 739px) {

  .details-8002 {
    height:  200px;
}

}
@media only screen and (max-height: 686px) {

  .details-8002 {
    height:  180px;
}

}

@media only screen and (max-height: 638px) {

  .details-8002 {
    height:  160px;
}
}

@media only screen and (max-height: 593px) {

  .details-8002 {
    height:  140px;
}
}
@media only screen and (max-height: 542px) {

  .details-8002 {
    height:  120px;
}
}

  
  .Languages_used-8001 {
    font-size: large;
    margin: 3% 1% 3% 0%;
    margin-bottom: 1%;
  }
  

  .card-footer-8001 {
    position: absolute; 
    top: 0; 
    right: 0; 
    margin-top: 20px; 
    margin-right: 20px; 
  }





