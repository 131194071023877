
.body-500-wrapper {
  display: flex;
  justify-content: flex-start; /* or use 'space-around' if you want some space between them */
  flex-wrap: wrap; /* allow items to wrap onto multiple lines */
  margin-top: 100px;
  margin-bottom: 0%;
  margin-left: 8%;
  margin-right: 8%;
  
  
}
.scale-500{
  margin-top: 5%; /* Adds a top margin */
  width: 95vw; /* Sets the width to 95% of the viewport width */
  border: 2px solid rgb(39, 33, 33); /* Adjust the color and size of the border as needed */
  background-color: transparent; /* Keeps the background of the box clear */
  display: block; /* Ensures the div is treated as a block element */
  margin-left: auto; /* Centers the block horizontally by setting the left margin to auto */
  margin-right: auto; /* Centers the block horizontally by setting the right margin to auto */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  height: auto; /* Adjust the height as needed, or set it to auto for content-dependent height */
  border-radius: 25px; /* Sets the radius of the border corners */
}
.cont-500-wrapper {
  display: flex;
  justify-content: flex-start; /* or use 'space-around' if you want some space between them */
  flex-wrap: wrap; /* allow items to wrap onto multiple lines */
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 9.5%;
  margin-right: 5.7%;
}

.cont-500-1,
.cont-500-2,
.cont-500-3,
.cont-500-4 {
  position: relative;
  display: flex;
  flex-direction: column; /* Set the direction to column for vertical stacking */
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  flex: 1;  
}

.cont-500-1 .p1,
.cont-500-2 .p1,
.cont-500-3 .p1,
.cont-500-4 .p1{
  font-size: 1.3vw;
  color: #000;
}

.cont-500-1 .p2,
.cont-500-2 .p2,
.cont-500-3 .p2,
.cont-500-4 .p2{
  font-size: 0.75vw;
  color: #000;

}


.body-500-1,
.body-500-2,
.body-500-3,
.body-500-4 {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  flex: 1; 
 

}
.body-500-4 img{
  width: 4.5vw;
  height: 4.5vw;
  left: 11%;
  position: relative;
  z-index: 1; /* Set a higher z-index value */
}
.body-500-1 img{
  width: 3.5vw;
  left: 9.5%;
  position: relative;
  z-index: 1; /* Set a higher z-index value */
}
.body-500-2 img{
  width: 3.5vw;
  left: 9.5%;
  position: relative;
  z-index: 1; /* Set a higher z-index value */
}

.body-500-3 img{
  width: 4vw;
  left: 10%;
  position: relative;
  z-index: 1; /* Set a higher z-index value */
}


.flex-500 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5vw; /* Adjusted width as a percentage of the viewport width */
  height: 1.5vw; /* Adjusted height as a percentage of the viewport width */
  margin: 0 -1.5vw 0 0; /* Adjusted margin for smaller cubes */

}

/* cube */
.cube {
  position: relative;
  width: 0.5vw; /* Adjusted width as a percentage of the viewport width */
  height: 0.5vw; /* Adjusted height as a percentage of the viewport width */
  margin: 0 1.5vw 0 0; /* Adjusted margin for smaller cubes */
  transform-style: preserve-3d;
  

}

.wall1{
  
  border: solid 2px  red; /* Reduced border thickness for smaller cubes */
}
.wall2{
  
  border: solid 2px  blue; /* Reduced border thickness for smaller cubes */
}
.wall3{
  
  border: solid 2px  yellow; /* Reduced border thickness for smaller cubes */
}
.wall4{
  
  border: solid 2px  white; /* Reduced border thickness for smaller cubes */
}
.wall5{
  
  border: solid 2px  green; /* Reduced border thickness for smaller cubes */
}
.wall6{
  
  border: solid 2px  purple; /* Reduced border thickness for smaller cubes */
}

.wall1,
.wall2,
.wall3,
.wall4,
.wall5,
.wall6 {
  width: 1.5vw; /* Adjusted width as a percentage of the viewport width */
  height: 1.5vw; /* Adjusted height as a percentage of the viewport width */
  position: absolute;
  left: calc(-0.75vw);
  top: calc(-0.75vw);
  text-align: center;
  line-height: 1.5vw; /* Adjusted line-height as a percentage of the viewport width */
  border-radius: 15%;
}

.front {
  transform: translateZ(calc(1.5vw / 2));
}
.back {
  transform: translateZ(calc(-1.5vw / 2)) rotateY(180deg);
}
.right {
  transform: translateX(calc(1.5vw / 2)) rotateY(90deg);
}
.left {
  transform: translateX(calc(-1.5vw / 2)) rotateY(-90deg);
}
.top {
  transform: translateY(calc(-1.5vw / 2)) rotateX(90deg);
}
.bottom {
  transform: translateY(calc(1.5vw / 2)) rotateX(-90deg);
}

/* animation */


.start-animation  .cube:nth-of-type(1) {
  animation: rotation 3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s forwards;
}


.start-animation  .cube:nth-of-type(1) {
  animation: rotation 3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.6s forwards;
}
.start-animation  .cube:nth-of-type(2) {
  animation: rotation 3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.6s forwards;
}


.start-animation .flex-500:nth-of-type(2) .cube:nth-of-type(1) {
  animation: rotation 3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.7s forwards;
}
.start-animation .flex-500:nth-of-type(3) .cube:nth-of-type(2) {
  animation: rotation 3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.7s forwards;
}
.start-animation .flex-500:nth-of-type(4) .cube:nth-of-type(3) {
  animation: rotation 3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s forwards;
}


.start-animation .flex-500:nth-of-type(1) .cube:nth-of-type(1) {
  animation: rotation 3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s forwards;
}
.start-animation .flex-500:nth-of-type(2) .cube:nth-of-type(2) {
  animation: rotation 3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s forwards;
}
.start-animation .flex-500:nth-of-type(3) .cube:nth-of-type(3) {
  animation: rotation 3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s forwards;
}
.start-animation .flex-500:nth-of-type(4) .cube:nth-of-type(4) {
  animation: rotation 3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s forwards;
}


.start-animation .flex-500:nth-of-type(1) .cube:nth-of-type(2) {
  animation: rotation 3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s forwards;
}
.start-animation .flex-500:nth-of-type(2) .cube:nth-of-type(3) {
  animation: rotation 3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s forwards;
}
.start-animation .flex-500:nth-of-type(3) .cube:nth-of-type(4) {
  animation: rotation 3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s forwards;
}

.start-animation .flex-500:nth-of-type(1) .cube:nth-of-type(3) {
  animation: rotation 3s cubic-bezier(0.215, 0.61, 0.355, 1) 1s forwards;
}
.start-animation .flex-500:nth-of-type(2) .cube:nth-of-type(4) {
  animation: rotation 3s cubic-bezier(0.215, 0.61, 0.355, 1) 1s forwards;
}

.start-animation .flex-500:nth-of-type(1) .cube:nth-of-type(4) {
  animation: rotation 3s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s forwards;
}

@keyframes rotation {
  100% {
    transform: rotateX(270deg) rotateY(270deg);
    border-radius: 030%;
  }
}

.start-color-animation.wall1,
.start-color-animation.wall2,
.start-color-animation.wall3,
.start-color-animation.wall4,
.start-color-animation.wall5,
.start-color-animation.wall6{
  animation: color 2s linear 2.5s forwards;
}

@keyframes color {


  50% {
    background-color:rgb(28, 31, 53);
    border: none; /* Reduced border thickness for smaller cubes */
    border-radius: 20%;

  }


  100% {
    background-color:black;
    border: none; /* Reduced border thickness for smaller cubes */
    border-radius: 0%;

  }
}

.fade-in {
  animation: fadeIn 1.5s ease-in-out 3s forwards; /* Added 3s delay to match the color animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


@keyframes fadeInp {
  
  100% {
    color: white;
  }
}
.fade-in-p {
  animation: fadeInp 1.5s ease-in-out 3s forwards; /* Removed delay from here */
}

@media (max-width: 1299px) {
  .scale-500{
    margin-top: 10%;
    
  }
}


@media (max-width: 800px) {

  .body-500-1 img,
  .body-500-2 img,
  .body-500-3 img,
  .body-500-4 img{
    left: 10%;
  }

 .body-500-1,
 .body-500-2,
  .body-500-3,
  .body-500-4 {
    margin-top: -20%;
    flex: 50%; /* Full width for small screens */
   
  }
  .body-500-3,
  .body-500-4
  {
    margin-top:20%;
  }
  

  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4 {
    left: 2.5%;
    margin-top: -20%;
    flex: 50%; /* Full width for small screens */
    
    transform: translateY(-60px);
  }
  .cont-500-3,
  .cont-500-4 {
    margin-top:20%;
  }

  .scale-500{
    transform: scale(1.5);
    margin-top: 20%; /* Adds a top margin */
    width: 50vw; /* Sets the width to 95% of the viewport width */
    background-color: transparent; /* Keeps the background of the box clear */
    display: block; /* Ensures the div is treated as a block element */
    margin-left: auto; /* Centers the block horizontally by setting the left margin to auto */
    margin-right: auto; /* Centers the block horizontally by setting the right margin to auto */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
    border-radius: 25px; /* Sets the radius of the border corners */
    height: 300px;
    margin-bottom: 20%;
  }



}

@media (max-width: 769px){
  .scale-500{
    margin-top: 100px;
  }
}

@media (max-width: 700px) {


  
  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4 {
    flex: 50%; /* Full width for small screens */
    transform: translateY(-40px);
  }


}

@media (max-width: 640px) {
.cube{
  visibility: hidden;
}
.fade-in {
  animation: fadeIn 1.5s ease-in-out 3s forwards; /* Added 3s delay to match the color animation */
}
.fade-in-p {
  animation: fadeInp 1.5s ease-in-out 3s forwards; /* Removed delay from here */
}

  .body-500-1 img,
  .body-500-2 img,
  .body-500-3 img,
  .body-500-4 img{
    transform: scale(2);
    left: 3.5%;
    

  }

  .body-500-1,
  .body-500-2,
  .body-500-3,
  .body-500-4  {
    transform: scale(2);
    flex: 100%; /* Full width for small screens */
    margin-top:0%;
    margin-bottom: 35%;
    left: -4.5%;

  }
  .body-500-1{
    margin-top: -15%;
  }

  
  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4  {
    left: 2.5%;
    flex: 100%; /* Full width for small screens */
    margin-top:30%;
    transform: translateY(-560px);
    left:-0.1%;
  }
  .cont-500-1{
    margin-top:0%;
  }
  
  .cont-500-1 .p1,
.cont-500-2 .p1,
.cont-500-3 .p1,
.cont-500-4 .p1{
  font-size: 3.5vw;
  color: #000;
}
.cont-500-1 .p2,
.cont-500-2 .p2,
.cont-500-3 .p2,
.cont-500-4 .p2{
  font-size: 2vw;
  color: #000;

}
  .scale-500{
    transform: translateY(-125px) ;
      margin-top: 150px; /* Adds a top margin */
      width: 65vw; /* Sets the width to 95% of the viewport width */
      background-color: transparent; /* Keeps the background of the box clear */
      display: block; /* Ensures the div is treated as a block element */
      margin-left: auto; /* Centers the block horizontally by setting the left margin to auto */
      margin-right: auto; /* Centers the block horizontally by setting the right margin to auto */
      box-sizing: border-box; /* Includes padding and border in the element's total width and height */
      border-radius: 25px; /* Sets the radius of the border corners */
      height: 650px;
      margin-bottom: 20%;
    
  
  }
}


@media (max-width: 600px) {

  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4  {
    flex: 100%; /* Full width for small screens */
    transform: translateY(-520px);
  }

}


@media (max-width: 590px) {

  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4  {
    transform: translateY(-480px);
  }
  .scale-500{
    margin-top: 140px;
    height: 620px;
  }
}

@media (max-width: 527px) {

  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4  {
    transform: translateY(-450px);
  }
  .scale-500{
    
    height: 550px;
  }

}

@media (max-width: 502px) {

  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4  {
    transform: translateY(-420px);
  }

}


@media (max-width: 477px) {

  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4  {
    transform: translateY(-400px);
  }
  .scale-500{
    margin-top: 150px;
    height: 520px;
  }

}

@media (max-width: 444px) {


  .scale-500{
    margin-top: 160px;
    height: 500px;
  }
  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4  {
    margin-top:32%;
    transform: translateY(-380px);
    left:-0.1%;
  }
  .cont-500-1{
    margin-top:0%;
  }
}
@media (max-width: 390px) {

  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4  {
    transform: translateY(-460px);
  }


}

@media (max-width: 422px) {

  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4  {
    transform: translateY(-360px);
  }


}

@media (max-width: 402px) {


  .body-500-1,
  .body-500-2,
  .body-500-3,
  .body-500-4  {

    margin-top:0%;
    margin-bottom: 40%;
    left: -4.5%;

  }
  .body-500-1{
    margin-top: -15%;
  }

  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4  {
    margin-bottom: 6%;
    transform: translateY(-385px);
  }
  .cont-500-1 .p1,
.cont-500-2 .p1,
.cont-500-3 .p1,
.cont-500-4 .p1{
  font-size: 3.5vw;
}
.cont-500-1 .p2,
.cont-500-2 .p2,
.cont-500-3 .p2,
.cont-500-4 .p2{
  font-size: 2vw;
  

}
}

@media (max-width: 380px) {

  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4  {
    transform: translateY(-360px);
  }
  .scale-500{

    
  }

}
@media (max-width: 355px) {

  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4  {
    transform: translateY(-340px);
  }

  .scale-500{

  }

}
@media (max-width: 333px) {

  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4  {
    transform: translateY(-320px);
  }

  .scale-500{

    height: 450px;
  }
}
@media (max-width: 313px) {

  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4  {
    transform: translateY(-300px);
  }
  .scale-500{

  }


}
@media (max-width: 295px) {

  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4  {
    transform: translateY(-280px);
  }


}

@media (max-width: 280px) {

  .cont-500-1,
  .cont-500-2,
  .cont-500-3,
  .cont-500-4  {
    transform: translateY(-260px);
  }

}

