.cards-container {
  height: relative;
  max-height: 100vh; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrollbar */
}


.cards-800 {
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}


.card-800 {
  display: flex;
  flex-direction: column;
  flex: 0 0 33.3333333%;
  padding: 0 0 0 1em;
  margin: 1em auto; /* Centering using margin */
  transition: transform 1s ease-in-out, max-width 1s linear, height 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  animation: fadeIn 1s ease-in-out; /* Adding animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px); /* Slide in from top */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Slide to original position */
  }
}

.card-800.expanded {
  max-width: 100vw;
  height: 120vh;
  flex: 0 0 100%;
  animation: expand 0.1s ease-in-out; /* Adding animation */
}

@keyframes expand {
  from {
    max-width: 33.3333333%; /* Start expanding from original width */
    height: auto; /* Start expanding from original height */
  }
  to {
    max-width: 100vw; /* Expand to full width of viewport */
    height: 95vh; /* Expand to 95% of viewport height */
  }
}


.card-content-800 {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  padding: 1em;
  border-radius: 5%;
  border: 2px solid #333232;

  max-width: 410px;
}

.card-800.expanded .card-content-800 {
  max-width: 95vw;
}

.card-body-800 {
  padding-left: 10px;
  padding-right: 5px;
  flex-grow: 1;
  height: 11vh;
  width: 108%;
  margin-left: -15px;
  margin-top: 15px;
  overflow-y: auto; /* Add this line to enable vertical scrolling if content exceeds max height */
}

.card-header__image-800 {
  transform: scale(1.4);
  overflow: hidden;
  background: #0000; /* Gradient from #001124 to black to #001124 */
  z-index: 0;
  width: 70%; /* Adjusted width */
  max-width: 100%;
  max-height: 120%;
  margin: auto;
  transition: transform 600ms ease-out;
  transform-origin: center;
  will-change: transform;
}


@media (max-width: 1629px) {

  .card-800.expanded {
    max-width: 100vw;
    height: 150vh;
    flex: 0 0 100%;
    animation: expand 0.1s ease-in-out; /* Adding animation */
  }
  .card-body-800 {
    height: 10vh;
  }
}



@media (max-width: 1473px) {
  .card-body-800 {
    height: 12vh;
  }
}

@media (max-width: 1085px) {
  .cards-800 {
    margin-left: 0%;
  }
  .card-800 {
    flex: 0 0 50%;
    
  }
  .cards-container {
    overflow-y: auto;
  }
  .card-body-800 {
    flex-grow: 1;
    max-height: 18vh;
    width: 107.5%;
    margin-left: -15px;
    overflow-y: auto; /* Add this line to enable vertical scrolling if content exceeds max height */
  }
  .card-body__desc-800 {
    text-align: left;
    font-size: 100%;
  }
  .logo-800 {
    margin-bottom: -2%;
}
}
@media only screen and (max-width: 769px) {

.cards-container{
  margin-top: -100px;
}


}
@media only screen and (max-width: 847px) {
  .logo-800 {
    
    width:auto;
    height: 23px;
    margin-bottom: 0%;
}

  .card-800.expanded {
    max-width: 100vw;
    height: 100vh;
    flex: 0 0 100%;
    animation: expand 0.1s ease-in-out; /* Adding animation */
  }
  .card-body-800 {
    max-height: 15vh;
  }
}

@media only screen and (max-width: 390px) {
  .card-header__image-800{
    margin-top: 2%;
  }
  .card-800.expanded {
    max-width: 100vw;
    height: 110vh;
    flex: 0 0 100%;
    animation: expand 0.1s ease-in-out; /* Adding animation */
  }
}

@media only screen and (max-width: 280px) {
  .card-800.expanded {
    max-width: 100vw;
    height: 120vh;
    flex: 0 0 100%;
    animation: expand 0.1s ease-in-out; /* Adding animation */
  }
}

@media (max-width: 767px) {
  .logo-800 {
    
    width:auto;
    height: 18px;
    margin-bottom: 0%;
}
  .cards-800 {
    justify-content: center;
  }
  
  .card-800 {
    flex: 0 0 50%;
    
  }
  .cards-container {
    overflow-y: auto;
    
  }
  .card-body-800 {
    flex-grow: 1;
    max-height: 12vh;
    width: 109%;
    margin-left: -15px;
    overflow-y: auto; /* Add this line to enable vertical scrolling if content exceeds max height */
    
  }
  .logo-800 {
    margin-bottom: -2%;
}


  .card-body__header-800 {
    font-family: 'Oswald', sans-serif;
    color: white; /* Change font color to white */
    
  }


  .card-body__desc-800 {
    text-align: left;
    font-size: 95%;
  }
  
  
 
}

@media (max-width: 635px) {
  .logo-800 {
    
    width:auto;
    height: 20px;
    margin-bottom: 0%;
}
  .card-800 {
    flex: 0 0 100%;
  }
  .cards-container {
    overflow-y: auto;
  }
  
  .card-content-800{
    margin-top: 0;
    height: 53vh; /* Adjust the height as needed */
    width: 70vw;
  }
  .card-body-800 {
    flex-grow: 1;
    max-height: 18vh;
    margin-bottom: 4%;
    width: 109%;
    margin-left: -15px;
    overflow-y: auto; /* Add this line to enable vertical scrolling if content exceeds max height */
  }

  .card-body__header-800 {
    margin-bottom: 5%;
    font-family: 'Oswald', sans-serif;
    color: white; /* Change font color to white */
  }
  .card-body__lang-800 {
    margin-bottom: -1%;
    word-spacing: 0.5em; /* Adjust this value to increase or decrease the space between words */
  }
  .card-body__header-800 {
    font-family: 'Oswald', sans-serif;
    color: white; /* Change font color to white */
  }

  .card-body__desc-800 {
    text-align: left;
    font-size: 90%;
  }
  

}
@media (max-width: 419px)   {
  .logo-800 {
    
    width:auto;
    height: 15px;
    margin-bottom: -3%;
}
.card-content-800{
  margin-top: 0;
  height: 53vh; /* Adjust the height as needed */
  width: 85vw;
}
.card-body-800 {
  flex-grow: 1;
  max-height: 18vh;
  margin-bottom: 4%;
  width: 109%;
  margin-left: -15px;
  overflow-y: auto; /* Add this line to enable vertical scrolling if content exceeds max height */
}
}

@media (max-width: 349px)   {

.card-content-800{
  margin-top: 0;
  height: 53vh; /* Adjust the height as needed */
  width: 85vw;
}
.card-body-800 {
  flex-grow: 1;
  max-height: 18vh;
  margin-bottom: 4%;
  width: 109%;
  margin-left: -15px;
  overflow-y: auto; /* Add this line to enable vertical scrolling if content exceeds max height */
}
}
@media (max-width: 305px) 
{
  .logo-800 {
    width: 40px;
    height: auto;
    margin-bottom: -3%;
}
.card-content-800{
  margin-top: 0;
  height: 53vh; /* Adjust the height as needed */
  width: 85vw;
}
.card-body-800 {
  flex-grow: 1;
  max-height: 18vh;
  margin-bottom: 4%;
  width: 109%;
  margin-left: -15px;
  overflow-y: auto; /* Add this line to enable vertical scrolling if content exceeds max height */
}
}
/* Some styles for better view */
.body-800 {
  font-family: 'Quattrocento', serif;
  padding-top: 1em;
  color: white; /* Change font color to white */
}

.card-content-800 {
  background: #0000; /* Gradient from #001124 to black to #001124 */
  transition: box-shadow 300ms ease-in;
  
}


.card-content-800:hover {
  box-shadow: 0 11px 7px 0 rgba(0, 0, 0, 0.19);
}

.card-content-800:hover .card-header__image-800 {
  transform: scale(1.05);
}

.card-header-800 {
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0);
  perspective: 11b1616px;
  margin: -1.1em -1.1em 0;
}

.card-header-800:after {
  content: '';
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.card-body__desc-800 {
  text-align: left;
  font-size: 110%;
}
.card-body__header-800 {
  margin-bottom: 10%;
  font-family: 'Oswald', sans-serif;
  color: white; /* Change font color to white */
  margin-top: 2%;
}
.card-body__lang-800 {
  margin-bottom: 2%;
  word-spacing: 0.5em; /* Adjust this value to increase or decrease the space between words */
}
.card-body__lang-801 {
  margin-bottom: 2%;
  word-spacing: 0.5em; /* Adjust this value to increase or decrease the space between words */
  font-size: 80%;
}



.card-footer__date-800 {
  margin-top: 10;
  margin-bottom: 10px;
  background: #0000; /* Gradient from #001124 to black to #001124 */
}
.card-footer-800{
  margin-top: 12px;
}
.card-footer__btn-800 {
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  padding: 3px 30px;
  font-size: 14px;
  color: white; /* Change button text color to white */
  transition: background-color 200ms ease-out;
  background: #000; /* Gradient from #001124 to black to #001124 */
  background-color: black;
  border: 2px solid #333232;
  border-radius: 25px;
  margin-bottom: 9px;
}

.card-footer__btn-800:hover {
  color: white;
  background: #332c2c; /* Wet Asphalt */
  text-decoration: none;
}

.card-footer__btn-800:focus {
  text-decoration: none;
  color: white;
}

.card-footer__btn-800:active {
  background: #0000; /* Gradient from #001124 to black to #001124 */
}


/* For Webkit based browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 12px; /* width of the scrollbar */
  scrollbar-width: thin;

}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* color of the scroll thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the scroll thumb on hover */
}


.github-button-800 {
  display: inline-block;
  background-color: #333; /* Change the background color as desired */
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  padding-top: 2px;
  margin-left: 4px;
  background: #000; /* Gradient from #001124 to black to #001124 */
  background-color: black;
  border: 2px solid #333232;
  border-radius: 25px;
}

.github-button-800:hover {
  background-color: #332c2c; /* Change the hover background color as desired */
}

.link-button-800 {
  display: inline-block;
  background-color: #333; /* Change the background color as desired */
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  padding-top: 2px;
  margin-left: 4px;
  padding-left: 2px;
  padding-right: 2px;
  background: #000; /* Gradient from #001124 to black to #001124 */
  background-color: black;
  border: 2px solid #333232;
  border-radius: 10px;
}

.link-button-800:hover {
  background-color: #332c2c; /* Change the hover background color as desired */
}

.icon-800 {
  font-size: 20px; /* Adjust the size of the icons as needed */
}
.github-800{
  margin-left: -3px; /* Add some spacing between the icons */
}
