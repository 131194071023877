/* styles.css or your preferred stylesheet */
.textBoxContainer15 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: rgba(22, 17, 17, 0.7);
    backdrop-filter: blur(10px);
    width: 600px;
    height: relative;
    border-radius: 10px;
    margin-top: 800px;
}

.textBoxHeader15 {
    color: #ffffff;
    font-size: 27px;
    margin-bottom: 10px;
}

.textBoxContent15 {
    margin-top: 15px;
}

.educationItem15 {
    margin-bottom: 20px;
}

.degree15 {
    color: #ffffff;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 5px;
}

.university15 {
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 5px;
}

.year15, .gpa15, .courses15 {
    color: #ffffff;
    font-size: 17px;
    margin-bottom: 5px;
}
.courses15{
    margin-top: 5px;
}

.onlineCourses15 {
    color: #ffffff;
    font-size: 18px;
    margin-top: 20px;
}

@media (max-width: 800px) {
.textBoxContainer15{
    
    width: 450px;
    font-size: 50%;
    margin-top: 400px;
}
.textBoxHeader15 {
    color: #ffffff;
    font-size: 25px;
    margin-bottom: 10px;
}

.degree15 {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.university15 {
    color: #ffffff;
    font-size: 15px;
    margin-bottom: 5px;
}

.year15, .gpa15, .courses15 {
    color: #ffffff;
    font-size: 15px;
    margin-bottom: 5px;
}
.courses15{
    margin-top: 5px;
}

.onlineCourses15 {
    color: #ffffff;
    font-size: 15px;
    margin-top: 20px;
}
}

@media (max-width: 650px) {
    .textBoxContainer15{
        width: 350px;
        font-size: 0%;
        margin-top: 300px;
    }
    .textBoxHeader15 {
        color: #ffffff;
        font-size: 22px;
        margin-bottom: 10px;
    }
    
    .degree15 {
        color: #ffffff;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 5px;
    }
    
    .university15 {
        color: #ffffff;
        font-size: 12px;
        margin-bottom: 5px;
    }
    
    .year15, .gpa15, .courses15 {
        color: #ffffff;
        font-size: 12px;
        margin-bottom: 5px;
    }
    .courses15{
        margin-top: 5px;
    }
    
    .onlineCourses15 {
        color: #ffffff;
        font-size: 12px;
        margin-top: 20px;
    }
    }


    @media (max-width: 560px) {
        .textBoxContainer15{
            width: 300px;
            margin-top: 250px;
        }
        .textBoxHeader15 {
            color: #ffffff;
            font-size: 22px;
            margin-bottom: 10px;
        }
        
        .degree15 {
            color: #ffffff;
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 5px;
        }
        
        .university15 {
            color: #ffffff;
            font-size: 12px;
            margin-bottom: 5px;
        }
        
        .year15, .gpa15, .courses15 {
            color: #ffffff;
            font-size: 12px;
            margin-bottom: 5px;
        }
        .courses15{
            margin-top: 5px;
        }
        
        .onlineCourses15 {
            color: #ffffff;
            font-size: 12px;
            margin-top: 20px;
        }
        }
        @media (max-width: 479px) {
            .textBoxContainer15{
                width: 65vw;
                margin-top: 400px;
            }
            .textBoxHeader15 {
                color: #ffffff;
                font-size: 22px;
                margin-bottom: 10px;
            }
            
            .degree15 {
                color: #ffffff;
                font-size: 15px;
                font-weight: bold;
                margin-bottom: 5px;
            }
            
            .university15 {
                color: #ffffff;
                font-size: 12px;
                margin-bottom: 5px;
            }
            
            .year15, .gpa15, .courses15 {
                color: #ffffff;
                font-size: 12px;
                margin-bottom: 5px;
            }
            .courses15{
                margin-top: 5px;
            }
            
            .onlineCourses15 {
                color: #ffffff;
                font-size: 12px;
                margin-top: 20px;
            }
            }