@media (min-width: 769px) {

body{
  padding:0;
  margin:0;
  
}

.App {
  text-align: center;
  background:#0d0d0e;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** title switch moon to sun */
.title {
  background-color: transparent;
  color: white;
  font-size: 1vw; /* Adjust the font size as per your preference */
  width: 50vw;
  height: 10vh;
  
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  flex-wrap: wrap; /* Allow text to wrap within the element */
  overflow: hidden; /* Hide overflowing text */
}

.title1 {
  background-color: rgba(156, 89, 245, 0.8);
  color: white;.title {
  background-color: transparent;
  color: white;
  font-size: 1vw; /* Adjust the font size as per your preference */
  width: 50vw;
  height: 10vh;
  
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  flex-wrap: wrap; /* Allow text to wrap within the element */
  overflow: hidden; /* Hide overflowing text */
}


  font-size: 1vw; /* Adjust the font size as per your preference */
  width: 50vw;
  height: 10vh;
  
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  flex-wrap: wrap; /* Allow text to wrap within the element */
  overflow: hidden; /* Hide overflowing text */
}



/* DEMO-SPECIFIC STYLES */
.typewriter text {
  color: #fff;
  font-family: monospace;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid white; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  
  animation: 
    typing 3.5s steps(30, end),
    blink-caret .5s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 95% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: white }
}

.container {
  width: 100%;
  height: 100vh;
  overflow-y:auto; /* Use "auto" instead of "scroll" to show the scrollbar only when needed */
  scroll-snap-type: y mandatory;
}

.slides
{
  width:100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content:center ;
  gap: 50px;
  color:#fff;
  background: linear-gradient(454deg, #fff, #fff);
}
 
 
.download-button {
  position: relative;
  border-width: 0;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  z-index: 1;
 }
 
 .download-button .docs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-height: 40px;
  padding: 0 10px;
  border-radius: 4px;
  z-index: 1;
  background-color: #242a35;
  border: solid 1px #e8e8e82d;
  transition: all .5s cubic-bezier(0.77, 0, 0.175, 1);
 }
 
 .download-button:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
 }
 
 .download {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;
  z-index: -1;
  border-radius: 4px;
  transform: translateY(0%);
  background-color: #01e056;
  border: solid 1px #01e0572d;
  transition: all .5s cubic-bezier(0.77, 0, 0.175, 1);
 }
 
 .download-button:hover .download {
  transform: translateY(100%)
 }
 
 .download svg polyline,.download svg line {
  animation: docs 1s infinite;
 }
 
 @keyframes docs {
  0% {
   transform: translateY(0%);
  }
 
  50% {
   transform: translateY(-15%);
  }
 
  100% {
   transform: translateY(0%);
  }
 }
 
}

/*000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000*/
@media (max-width: 1400px) {
 
  body{
    padding:0;
    margin:0;
    
  }
  
  .App {
    text-align: center;
    background:#0d0d0e;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /** title switch moon to sun */
  .title {
    background-color: transparent;
    color: white;
    font-size: 1.5em; /* Adjust the font size for font 3 */
    width: 50vw;
    height: auto; /* Allow the height to adjust based on content */
    
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    flex-wrap: wrap; /* Allow text to wrap within the element */
  }
  
  .title1 {
    background-color: rgba(156, 89, 245, 0.8);
    color: white;
      
      .title {
      background-color: transparent;
      color: white;
      font-size: 2em; /* Adjust the font size for font 3 */
      width: 50vw;
      height: auto; /* Allow the height to adjust based on content */
      
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      flex-wrap: wrap; /* Allow text to wrap within the element */
    }
  
  
    font-size: 2em; /* Adjust the font size for font 3 */
      width: 50vw;
      height: auto; /* Allow the height to adjust based on content */
      
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      flex-wrap: wrap; /* Allow text to wrap within the element */
    }
  
  
    .typewriter {
      color: #fff;
      font-family: monospace;
      white-space: pre-line;
      width: 95%; /* Adjust as needed */
      margin: 0 auto;
      letter-spacing: .15em;
      animation:
        typing 3.5s steps(30, end),
        blink-caret .5s step-end infinite;
    }
  
  
  
    .container {
      width: 100%;
      height: auto; /* Allow the height to adjust based on content */
      overflow-y:auto; /* Use "auto" instead of "scroll" to show the scrollbar only when needed */
      scroll-snap-type: y mandatory;
    }
  
    .slides
    {
      width:100%;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content:center ;
      gap: 50px;
      color:#fff;
      background: linear-gradient(454deg, #fff, #fff);
    }
   
   
    .download-button {
    position: relative;
    border-width: 0;
    color: white;
    font-size: 15px;
    font-weight: 600;
    border-radius: 4px;
    z-index: 1;
   }
   
   .download-button .docs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    min-height: 40px;
    padding: 0 10px;
    border-radius: 4px;
    z-index: 1;
    background-color: #242a35;
    border: solid 1px #e8e8e82d;
    transition: all .5s cubic-bezier(0.77, 0, 0.175, 1);
   }
   
   .download-button:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
   }
   
   .download {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 90%;
    margin: 0 auto;
    z-index: -1;
    border-radius: 4px;
    transform: translateY(0%);
    background-color: #01e056;
    border: solid 1px #01e0572d;
    transition: all .5s cubic-bezier(0.77, 0, 0.175, 1);
   }
   
   .download-button:hover .download {
    transform: translateY(100%)
   }
   
   .download svg polyline,.download svg line {
    animation: docs 1s infinite;
   }
   
   @keyframes docs {
    0% {
     transform: translateY(0%);
    }
   
    50% {
     transform: translateY(-15%);
    }
   
    100% {
     transform: translateY(0%);
    }
   }
   
  }


  /*000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000*/
@media (max-width: 1039px) {
 
  body{
    padding:0;
    margin:0;
    
  }
  
  .App {
    text-align: center;
    background:#0d0d0e;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /** title switch moon to sun */
  .title {
    background-color: transparent;
    color: white;
    font-size: 1em; /* Adjust the font size for font 3 */
    width: 50vw;
    height: auto; /* Allow the height to adjust based on content */
    
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    flex-wrap: wrap; /* Allow text to wrap within the element */
  }
  
  .title1 {
    background-color: rgba(156, 89, 245, 0.8);
    color: white;
      
      .title {
      background-color: transparent;
      color: white;
      font-size: 2em; /* Adjust the font size for font 3 */
      width: 50vw;
      height: auto; /* Allow the height to adjust based on content */
      
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      flex-wrap: wrap; /* Allow text to wrap within the element */
    }
  
  
    font-size: 2em; /* Adjust the font size for font 3 */
      width: 50vw;
      height: auto; /* Allow the height to adjust based on content */
      
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      flex-wrap: wrap; /* Allow text to wrap within the element */
    }
  
  
    .typewriter {
      color: #fff;
      font-family: monospace;
      white-space: pre-line;
      width: 95%; /* Adjust as needed */
      margin: 0 auto;
      letter-spacing: .15em;
      animation:
        typing 3.5s steps(30, end),
        blink-caret .5s step-end infinite;
    }
  
  
  
    .container {
      width: 100%;
      height: auto; /* Allow the height to adjust based on content */
      overflow-y:auto; /* Use "auto" instead of "scroll" to show the scrollbar only when needed */
      scroll-snap-type: y mandatory;
    }
  
    .slides
    {
      width:100%;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content:center ;
      gap: 50px;
      color:#fff;
      background: linear-gradient(454deg, #fff, #fff);
    }
   
   
    .download-button {
    position: relative;
    border-width: 0;
    color: white;
    font-size: 15px;
    font-weight: 600;
    border-radius: 4px;
    z-index: 1;
   }
   
   .download-button .docs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    min-height: 40px;
    padding: 0 10px;
    border-radius: 4px;
    z-index: 1;
    background-color: #242a35;
    border: solid 1px #e8e8e82d;
    transition: all .5s cubic-bezier(0.77, 0, 0.175, 1);
   }
   
   .download-button:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
   }
   
   .download {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 90%;
    margin: 0 auto;
    z-index: -1;
    border-radius: 4px;
    transform: translateY(0%);
    background-color: #01e056;
    border: solid 1px #01e0572d;
    transition: all .5s cubic-bezier(0.77, 0, 0.175, 1);
   }
   
   .download-button:hover .download {
    transform: translateY(100%)
   }
   
   .download svg polyline,.download svg line {
    animation: docs 1s infinite;
   }
   
   @keyframes docs {
    0% {
     transform: translateY(0%);
    }
   
    50% {
     transform: translateY(-15%);
    }
   
    100% {
     transform: translateY(0%);
    }
   }
   
  }



/*000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000*/
@media (max-width: 768px) {
 
body{
  padding:0;
  margin:0;
  
}

.App {
  text-align: center;
  background:#0d0d0e;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** title switch moon to sun */
.title {
  background-color: transparent;
  color: white;
  font-size: 2em; /* Adjust the font size for font 3 */
  width: 50vw;
  height: auto; /* Allow the height to adjust based on content */
  
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  flex-wrap: wrap; /* Allow text to wrap within the element */
}

.title1 {
  background-color: rgba(156, 89, 245, 0.8);
  color: white;
    
    .title {
    background-color: transparent;
    color: white;
    font-size: 2em; /* Adjust the font size for font 3 */
    width: 50vw;
    height: auto; /* Allow the height to adjust based on content */
    
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    flex-wrap: wrap; /* Allow text to wrap within the element */
  }


  font-size: 2em; /* Adjust the font size for font 3 */
    width: 50vw;
    height: auto; /* Allow the height to adjust based on content */
    
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    flex-wrap: wrap; /* Allow text to wrap within the element */
  }


  .typewriter {
    color: #fff;
    font-family: monospace;
    white-space: pre-line;
    width: 95%; /* Adjust as needed */
    margin: 0 auto;
    letter-spacing: .15em;
    animation:
      typing 3.5s steps(30, end),
      blink-caret .5s step-end infinite;
  }



  .container {
    width: 100%;
    height: auto; /* Allow the height to adjust based on content */
    overflow-y:auto; /* Use "auto" instead of "scroll" to show the scrollbar only when needed */
    scroll-snap-type: y mandatory;
  }

  .slides
  {
    width:100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content:center ;
    gap: 50px;
    color:#fff;
    background: linear-gradient(454deg, #fff, #fff);
  }
 
 
  .download-button {
  position: relative;
  border-width: 0;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  z-index: 1;
 }
 
 .download-button .docs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-height: 40px;
  padding: 0 10px;
  border-radius: 4px;
  z-index: 1;
  background-color: #242a35;
  border: solid 1px #e8e8e82d;
  transition: all .5s cubic-bezier(0.77, 0, 0.175, 1);
 }
 
 .download-button:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
 }
 
 .download {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;
  z-index: -1;
  border-radius: 4px;
  transform: translateY(0%);
  background-color: #01e056;
  border: solid 1px #01e0572d;
  transition: all .5s cubic-bezier(0.77, 0, 0.175, 1);
 }
 
 .download-button:hover .download {
  transform: translateY(100%)
 }
 
 .download svg polyline,.download svg line {
  animation: docs 1s infinite;
 }
 
 @keyframes docs {
  0% {
   transform: translateY(0%);
  }
 
  50% {
   transform: translateY(-15%);
  }
 
  100% {
   transform: translateY(0%);
  }
 }
 
}

