


.About {
  overflow: auto; /* Add scrollbars when content exceeds the height */
  overflow-x: hidden;
  width: 100vw;
}
.starScreen .About {
  height: 100vh; /* Set a fixed height */

}

.starScreen .Container {
  background-color: black;
  display: flex;
  flex-direction: column; /* Set the flex-direction to column */
  justify-content: center;
  align-items: flex-start; /* Align items to the start (top) of the navbar */
  font-size: 0.6rem;
  position: fixed;
  left: 20px; /* Adjust the position to the desired distance from the left side */
  top: 50%;
  transform: translateY(-50%) translateX(-48%); /* Center the navbar vertically */
  z-index: 20;
  border-radius: 25px;
  width: 36px; /* Adjust the width as needed */
  max-height: 80vh; /* Limit the height of the navbar to 80% of the viewport height */
  font-family: monospace;
  color: #fff;
  white-space: nowrap;
  border: 2px solid #333232;
  font-weight: bolder;
}

.starScreen .Container li {
  display: block; /* Display list items as blocks to stack them vertically */
  padding: 8px; /* Adjust the padding as desired */
}
.starScreen .icon
{
  size: 3rem;
  color: white;
}



.Container {
  background-color: #8FB5F5;
  display: flex;
  flex-direction: column; /* Set the flex-direction to column */
  justify-content: center;
  align-items: flex-start; /* Align items to the start (top) of the navbar */
  font-size: 0.8rem;
  position: fixed;
  left: 20px; /* Adjust the position to the desired distance from the left side */
  top: 50%;
  transform: translateY(-50%); /* Center the navbar vertically */
  z-index: 20;
  border-radius: 25px;
  width: 40px; /* Adjust the width as needed */
  max-height: 80vh; /* Limit the height of the navbar to 80% of the viewport height */
  font-family: monospace;
  color: #1868f1;
  white-space: nowrap;
  border: 2px solid #347CF8; /* Add the border */
  font-weight: bolder;

}

.Container li {
  display: block; /* Display list items as blocks to stack them vertically */
  padding: 10px; /* Adjust the padding as desired */
}

.icon
{
  size: 3rem;
  color: rgb(43, 5, 78);
}

/*edit this part when you finish this page*/
.photos {
  height: relative;
  margin-bottom:10% ;
}

.up{
  
  height: 100%;
}

.Introduction {
  margin-top: 90px;
  height: 100%;
}


.starScreen .Introduction {
  height: 100%;
}

.Education {
  height: 500px;
}
@media (max-width: 800px) {
  .Education {
    height: 0px;
  }

}
@media (max-width: 769px) {
  .Introduction {
    margin-top: 10px;
  }

}

.Quotes
{
  height: 100%;
}
.Experiance {
  height: relative;
  margin-top: 5%; /* Adds a top margin */
  width: 95vw; /* Sets the width to 95% of the viewport width */
  border: 2px solid rgb(39, 33, 33); /* Adjust the color and size of the border as needed */
  background-color: transparent; /* Keeps the background of the box clear */
  display: block; /* Ensures the div is treated as a block element */
  margin-left: auto; /* Centers the block horizontally by setting the left margin to auto */
  margin-right: auto; /* Centers the block horizontally by setting the right margin to auto */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  border-radius: 25px; /* Sets the radius of the border corners */
}
.time-line-17 {
  display: inline-block; /* Makes the element as wide as its content */
  text-align: center;    /* Centers the text inside the element itself */
  margin-top: 10px;         /* Removes the default top margin */
  position: relative;    /* Optional based on further specific needs */
  left: 50%;             /* Moves the element halfway across the screen */
  transform: translateX(-50%); /* Centers the element by moving it back 50% of its own width */
    font-family: 'Verdana', 'Geneva', sans-serif;
  padding: 20px;   /* Adds a little space on the top, adjust as needed */
  border-radius: 30px;
  background: rgb(10,10,10);
  border:4px solid rgb(216, 191, 216);
  font-weight: 100;
  font-size: x-large;
}
@media (max-width: 950px) {
  .Experiance{
    margin-left: 50px;
    width: 85vw;
    

  }
  .time-line-17 {
    text-align: left;    /* Centers the text inside the element itself */
    left: 12%;             /* Moves the element halfway across the screen */

    border-radius: 20px;
  }

}
@media (max-width: 790px) {
  .time-line-17 {
    
    color: #ffffff;    /* Moves the element halfway across the screen */
    font-size: large;

  }


}
@media (max-width: 790px) {
  .Experiance{
    margin-top: -100px;

  }
  .time-line-17 {
    
    color: #ffffff;    /* Moves the element halfway across the screen */
    left: 14%;             /* Moves the element halfway across the screen */
    font-size: large;
  }


}
@media (max-width: 629px) {
  .Experiance{
    margin-top: -180px;

  }

}

@media (max-width: 570px) {
  .time-line-17 {
    
    color: #ffffff;    /* Moves the element halfway across the screen */
    left: 16%;             /* Moves the element halfway across the screen */
    font-size: large;
  }


}
@media (max-width: 490px) {
  .time-line-17 {
              /* Moves the element halfway across the screen */
    font-size: medium;
  }


}
@media (max-width: 490px) {
  .time-line-17 {
    left: 18%;             /* Moves the element halfway across the screen */

  }
}



@media (max-width: 420px) {
  .Experiance{
    margin-left: 50px;
    width: 80vw;
    margin-top: -220px;
  }
  .time-line-17 {
    
    color: #ffffff;    /* Moves the element halfway across the screen */
    left: 20%;             /* Moves the element halfway across the screen */
  }

}

@media (max-width: 395px) {

  .time-line-17 {
    left: 22%;             /* Moves the element halfway across the screen */
  }

}

@media (max-width: 355px) {

  .time-line-17 {
    left: 25%;             /* Moves the element halfway across the screen */
  }
  .Experiance{

    margin-top: -250px;
  }

}

@media (max-width: 315px) {

  .time-line-17 {
    left: 28%;             /* Moves the element halfway across the screen */
  }

}
@media (max-width: 290px) {

  .time-line-17 {
    left: 30%;             /* Moves the element halfway across the screen */
  }

}

.skills {
  height: relative;
}


/*
/* Active and graphics*/


.link {
text-decoration: none;
display: inline;
padding: 20px 20px;
font-weight: 700;
font-size: large;
cursor: pointer;
}


.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: flex;
  height: 2px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
  
}

 .nav-item:hover:after {
  width: 100%;
  background: #1868f1;
}
 .nav-item .active {
  color: #1868f1;
  border: 2px solid #1868f1;
}

.starScreen .nav-item:hover:after {
  width: 100%;
  background: #ffdd40;
  
}

.starScreen .nav-item .active {
  color: #ffdd40;
  border: 2px solid #ffdd40;
  
}

.starScreen .nav-item:hover:after {
  width: 100%;
  background: white;
}
.starScreen .nav-item .active {
  color: white;
  border: 2px solid white;
}

.tooltip {
  /* Add padding or margin to create space */
  padding-left: 7px;
}
.tooltip2 {
  opacity: 1;
  transition: opacity 0.5s ease-in-out, left 0.5s ease-in-out; /* Add transition for left */
  position: absolute; /* Ensure absolute positioning for left transition */
  left: 100%; /* Start position */
}

.tooltip2.hidden {
  opacity: 0;
  left: 0; /* End position */
}

.cent {
  position: relative;
  top: 20px;
  left: 45%;
}

.starScreen .HiOutlineDocumentText{
  position: fixed;
  left: 71.5%;
  top: 0.8%;
  font-size: 30px; /* Adjust the font size as needed */
  color: white;

}
.HiOutlineDocumentText{
  position: fixed;
  left: 71.5%;
  top: 0.8%;
  font-size: 30px; /* Adjust the font size as needed */
  color: blue;

}
/* Define the container for the text box */
.textbox-container {
  margin: auto;
  position: relative;

  width: 95vw; /* Sets the width to 95% of the viewport width */
  border: 2px solid rgb(39, 33, 33); /* Adjust the color and size of the border as needed */
  background-color: transparent; /* Keeps the background of the box clear */
  display: block; /* Ensures the div is treated as a block element */
  margin-left: auto; /* Centers the block horizontally by setting the left margin to auto */
  margin-right: auto; /* Centers the block horizontally by setting the right margin to auto */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  height: auto; /* Adjust the height as needed, or set it to auto for content-dependent height */
  border-radius: 25px; /* Sets the radius of the border corners */
}

.textbox-container2 {
  margin: auto;
  position: relative;
  width: 95vw; /* Sets the width to 95% of the viewport width */
  border: 2px solid rgb(39, 33, 33); /* Adjust the color and size of the border as needed */
  background-color: transparent; /* Keeps the background of the box clear */
  display: block; /* Ensures the div is treated as a block element */
  margin-left: auto; /* Centers the block horizontally by setting the left margin to auto */
  margin-right: auto; /* Centers the block horizontally by setting the right margin to auto */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  height: auto; /* Adjust the height as needed, or set it to auto for content-dependent height */
  border-radius: 25px; /* Sets the radius of the border corners */
  padding-bottom: 70px;
 
}
.textbox-container2 .span1{    
  background-color: rgb(27, 22, 22);
  color: white;
  padding: 8.5px 20px ;
  padding-right: 0px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border: none;
  height: 35px;
  font-family: monospace;
  float: right; /* This will push the button to the right */
  margin-top: 20px;
  margin-bottom: 20px;
  z-index: 1000000000;
  }

/* Define the text box on the left */
.textbox1 {
  color: white;
  position: relative;

  padding: 30px; /* Adjust padding as needed */
  box-sizing: border-box;
  width: calc(100% - 200px); /* Adjust the width based on image size */
  
}

/* Define images on the right (you can customize this for your specific images) */
.textbox1 img {
  max-width: 100%; /* Ensure images are responsive */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Add rounded corners to images */
  float: right; /* Float the image to the right */
}

.textbox1 p {
  font-size: 1.4vw; /* Adjust this as needed */
}



  .starScreen .content-container2 {
    background-color: #000;
    font-family: verdana sans-serif;
    margin: 20px auto;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    padding: 20px;
  }

  .left-paragraph {
    color: #1868f1;
    flex: 1;
  }

  .starScreen .left-paragraph {
    color: white;
    flex: 1;
  }

  .right-photo {
    flex: 1;
    text-align: right;
    float: left;
  }
  .right-photo .p{
    color: white;
  padding: 20px; /* Adjust padding as needed */
  box-sizing: border-box;
  width: calc(100% - 200px); /* Adjust the width based on image size */
  }

  .right-photo img {
    max-width: 100%;
    height: auto;
    border-radius: 10px; 
  }
  .middle-photo{
    flex: 0;
  }



  * {
    box-sizing: border-box
  }
  /* starting here ***********************************************************************************************/
      body {
    font-family: Verdana, sans-serif;
    margin: 0
  }
  .mySlides {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 600px;
    overflow: hidden; /* Ensure overflowing content is hidden */
  }
  
  .mySlides img {
    max-width: 100%; /* Allow the image to scale up to the container's width */
    height: auto; /* Maintain aspect ratio */
    max-height: 600px; /* Ensure the image doesn't exceed the container's height */
  }

  .mySlides1 {
    display: none
  }
  .mySlides2 {
    display: none
  }
  .mySlides3 {
    display: none
  }
  .fade-9002 {
    animation-name: fade;
    animation-duration: 1.5s;
  }

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  img {
    vertical-align: middle;
  }
  .slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
  }
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
    .prev {
      left: 0;
      border-radius: 3px 0 0 3px;
    }
  
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .text {
    color: #ffffff;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    text-align: center;
  }
  .numbertext {
    color: #ffffff;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
    background-color: #333232;
    z-index: 3000;
    border-radius: 25px 0px 52px 0;
  }
  .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #999999;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  .dot1 {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #999999;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  .dot2 {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #999999;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  .dot3 {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #999999;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  .active1,
  .dot:hover {
    background-color: #111111;
  }
  
 



  .blockquote {
    padding: 60px 80px 40px;
    position: relative;
}
.blockquote p {
    font-family: "Utopia-italic";
    font-size: 35px;
    font-weight: 700px;

    text-align: center;
}

/*blockquote p::before {
    content: "\f095"; 
    font-family: FontAwesome;
   display: inline-block;
   padding-right: 6px;
   vertical-align: middle;
  font-size: 180px;
 }*/

.blockquote:before {
  position: absolute;
  font-family: 'FontAwesome';
  top: 0;
  
  content:"\f10d";
  font-size: 200px;
  color: rgba(0,0,0,0.1);
   
}

.blockquote::after {
    content: "";
    top: 20px;
    left: 50%;
    margin-left: -100px;
    position: absolute;
    border-bottom: 3px solid #bf0024;
    height: 3px;
    width: 200px;
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);
.otro-blockquote{
  font-size: 1.4em;
  width:100%;
  margin:15px auto;
  font-family:Open Sans;
  font-style:italic;
  color: #dfcccc;
  padding:1.2em 30px 1.2em 75px;
  border-left:8px solid #78C0A8 ;
  line-height:1.6;
  position: relative;
  background:#2b2626;
}

.otro-blockquote::before{
  font-family:Arial;
  content: "\201C";
  color:#78C0A8;
  font-size:4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

.otro-blockquote::after{
  content: '';
}

.otro-blockquote span{
  display:block;
  color:#dfcccc;
  font-style: normal;
  font-weight: bold;
  margin-top:1em;
}

.books-dropdown-9002 {
  position: relative;
}
.dropdown-content-9002 {
  position: absolute;
  background-color: black;
  border: 2px solid rgb(39, 33, 33);
  border-radius: 25px;
  border-top-left-radius: 0%;
  padding: 10px;
  z-index: 10000;
  font-family: Verdana, sans-serif;
  font-size: medium;
  width: auto;
  max-width: 500px;
}

.dropdown-content-9002 ul {
  list-style-type: none;
  padding: 0;
}

.dropdown-content-9002 ul li {
  position: relative;
  padding-left: 20px; /* Adjust the spacing between the bullet point and text */
  margin-top: 2px;

}
.dropdown-content-9002 li:before {
  content: "\2022"; /* Unicode character for bullet point */
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff; /* Adjust the color of the bullet point */
  
}

.description-9002{
  font-size: 1.15vw !important;
  color: #fde4e4;
  font-style: italic;
  font-family: Calibri;
}



@media (max-width: 999px) {
  .textbox1 {
    font-size: 2vw;
    width: 95vw; /* Adjust the width based on image size */
    
  }
  .textbox1 p {
    font-size: 1.7vw; /* Adjust this as needed */
  }
  .dropdown-content-9002{
    font-size: 1.4vw; /* Adjust this as needed */
  }
  .quote-next-button-2004{
    margin-bottom: 15px;
  }
  .otro-blockquote{
    font-size: 1.7vw;
  }
  .description-9002{
    font-size: 1.6vw !important;
  }
  h3{
    color: white;
  }

  .slideshow-container {
    max-width: 95vw;
    position: relative;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}


@media (max-width: 651px) {
  
  .textbox1 p {
    font-size: 2.3vw; /* Adjust this as needed */
  }
  .dropdown-content-9002{
    font-size: 2vw; /* Adjust this as needed */
  }

  .otro-blockquote{
    font-size: 2.3vw;
  }
  .description-9002{
    font-size: 2.2vw !important;
  }

}


@media (max-width: 615px) {
  
  .textbox1 p {
    font-size: 2.6vw; /* Adjust this as needed */
  }
  .dropdown-content-9002{
    font-size: 2.3vw; /* Adjust this as needed */
  }

  .otro-blockquote{
    font-size: 2.6vw;
  }
  .description-9002{
    font-size: 2.5vw !important;
  }

}

@media (max-width: 576px) {
  
  .textbox1 p {
    font-size: 2.8vw; /* Adjust this as needed */
  }
  .dropdown-content-9002{
    font-size: 2.7vw; /* Adjust this as needed */
  }

  .otro-blockquote{
    font-size: 2.8vw;
  }
  .description-9002{
    font-size: 2.7vw !important;
  }

}

@media (max-width: 535px) {
  
  .textbox1 p {
    font-size: 3vw; /* Adjust this as needed */
  }
  .dropdown-content-9002{
    font-size: 2.9vw; /* Adjust this as needed */
  }

  .otro-blockquote{
    font-size: 3vw;
  }
  .description-9002{
    font-size: 2.9vw !important;
  }

}

@media (max-width: 499px) {
  
  .textbox1 p {
    font-size: 3.1vw; /* Adjust this as needed */
    padding-left: 15px;
  }
  .dropdown-content-9002{
    font-size: 3 vw; /* Adjust this as needed */
  }

  .otro-blockquote{
    font-size: 3.1vw;
  }
  .description-9002{
    font-size: 3 vw !important;
  }
  .otro-blockquote{
    margin-left: 15px;
  }

  .slideshow-container {
    max-width: 75vw;
  }

}

@media (max-width: 484px) {
  
  .textbox1 p {
    font-size: 3.4vw; /* Adjust this as needed */
    padding-left: 15px;
  }
  .dropdown-content-9002{
    font-size: 3.3 vw; /* Adjust this as needed */
  }

  .otro-blockquote{
    font-size: 3.4vw;
  }
  .description-9002{
    font-size: 3.3vw !important;
  }

}

@media (max-width: 454px) {
  
  .textbox1 p {
    font-size: 3.6vw; /* Adjust this as needed */
    padding-left: 15px;
  }
  .dropdown-content-9002{
    font-size: 3.5 vw; /* Adjust this as needed */
  }

  .otro-blockquote{
    font-size: 3.6vw;
  }
  .description-9002{
    font-size: 3.5vw !important;
  }

}

@media (max-width: 409px) {
  
  .title-2004{
    font-size: 3.4vw; /* Adjust this as needed */

  }

}
@media (max-width: 401px) {
  
  .otro-blockquote{
    padding:1.2em 30px 1.2em 40px;
  }

}


@media (max-width: 345px) {
  
  .textbox1 p {
    font-size: 3.8vw; /* Adjust this as needed */
    padding-left: 15px;
  }
  .dropdown-content-9002{
    font-size: 3.7 vw; /* Adjust this as needed */
  }

  .otro-blockquote{
    font-size: 3.8vw;
  }
  .description-9002{
    font-size: 3.7vw !important;
  }
  .title-2004{
    font-size: 3.2vw; /* Adjust this as needed */
  }
  .slideshow-container {
    margin-left: 15px;
  }

}

@media (max-width: 327px) {
  
  .textbox1 p {
    font-size: 4.2vw; /* Adjust this as needed */
    padding-left: 15px;
  }
  .dropdown-content-9002{
    font-size: 4.1 vw; /* Adjust this as needed */
  }

  .otro-blockquote{
    font-size: 4.2vw;
  }
  .description-9002{
    font-size: 4.1vw !important;
  }

}



@media (max-width: 1870px) {
  .mySlides {
    height: 500px;
  }
  
  .mySlides img {
    max-height: 500px; /* Ensure the image doesn't exceed the container's height */
  }
}

@media (max-width: 1648px) {
  .mySlides {
    height: 450px;
  }
  
  .mySlides img {
    max-height: 450px; /* Ensure the image doesn't exceed the container's height */
  }
}
@media (max-width: 1648px) {
  .mySlides {
    height: 400px;
  }
  
  .mySlides img {
    max-height: 400px; /* Ensure the image doesn't exceed the container's height */
  }
}
@media (max-width: 1342px) {
  .mySlides {
    height: 380px;
  }
  
  .mySlides img {
    max-height: 380px; /* Ensure the image doesn't exceed the container's height */
  }
}
@media (max-width: 1240px) {
  .mySlides {
    height: 350px;
  }
  
  .mySlides img {
    max-height: 350px; /* Ensure the image doesn't exceed the container's height */
  }
}

@media (max-width: 1164px) {
  .mySlides {
    height: 325px;
  }
  
  .mySlides img {
    max-height: 325px; /* Ensure the image doesn't exceed the container's height */
  }
}
@media (max-width: 1080px) {
  .mySlides {
    height: 300px;
  }
  
  .mySlides img {
    max-height: 300px; /* Ensure the image doesn't exceed the container's height */
  }
}
@media (max-width: 1000px) {
  .mySlides {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px; /* Ensure container height is consistent */
    overflow: hidden; /* Hide overflow content */
    position: relative; /* Positioning context for the image */
  }
  
  .mySlides img {
    max-width: 100%;
    height: auto;
    max-height: 400px; /* Ensure the image doesn't exceed the container's height */
    position: absolute; /* Position the image absolutely */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
  }
}
@media (max-width: 823px) {
  .mySlides {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px; /* Ensure container height is consistent */
    overflow: hidden; /* Hide overflow content */
    position: relative; /* Positioning context for the image */
  }
  
  .mySlides img {
    max-width: 100%;
    height: auto;
    max-height: 300px; /* Ensure the image doesn't exceed the container's height */
    position: absolute; /* Position the image absolutely */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
  }
}
@media (max-width: 760px) {
  .mySlides {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px; /* Ensure container height is consistent */
    overflow: hidden; /* Hide overflow content */
    position: relative; /* Positioning context for the image */
  }
  
  .mySlides img {
    max-width: 100%;
    height: auto;
    max-height: 250px; /* Ensure the image doesn't exceed the container's height */
    position: absolute; /* Position the image absolutely */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
  }
}
@media (max-width: 479px) {
  .numbertext
  {
    font-size: 8px;
  }
  .mySlides {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Ensure container height is consistent */
    overflow: hidden; /* Hide overflow content */
    position: relative; /* Positioning context for the image */
  }
  
  .mySlides img {
    max-width: 100%;
    height: auto;
    max-height: 200px; /* Ensure the image doesn't exceed the container's height */
    position: absolute; /* Position the image absolutely */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
  }
}
@media (max-width: 393px) {
  .mySlides {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px; /* Ensure container height is consistent */
    overflow: hidden; /* Hide overflow content */
    position: relative; /* Positioning context for the image */
  }
  
  .mySlides img {
    max-width: 100%;
    height: auto;
    max-height: 180px; /* Ensure the image doesn't exceed the container's height */
    position: absolute; /* Position the image absolutely */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
  }
}





