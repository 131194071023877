body {
    background-image: radial-gradient(circle farthest-corner at center, #3C4B57 0%, #1C262B 100%);
  }
  
  .stars {
    position: absolute;
    top: calc(50% - 256px); /* Adjust top and left for positioning */
    left: calc(50% - 256px);
    width: 512px; /* Increase the size of the stars container */
    height: 512px;
    border-radius: 50%;
    perspective: 800px;
  }
  
  .inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  
  .inner.one {
    left: 0%;
    top: 0%;
    animation: rotate-one 3s linear infinite;
    border-bottom: 5px solid #EFEFFA; /* Increase the border thickness */
  }
  
  .inner.two {
    right: 0%;
    top: 0%;
    animation: rotate-two 3s linear infinite;
    border-right: 5px solid #EFEFFA; /* Increase the border thickness */
  }
  
  .inner.three {
    right: 0%;
    bottom: 0%;
    animation: rotate-three 3s linear infinite;
    border-top: 5px solid #EFEFFA; /* Increase the border thickness */
  }
  
  @keyframes rotate-one {
    0% {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
  }
  
  @keyframes rotate-two {
    0% {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
  }
  
  @keyframes rotate-three {
    0% {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
  }
  