.centered-button-2001 {
    position: fixed; /* Fixed positioning relative to the viewport */
    top: 65%; /* Center vertically */
    left: 45%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Correct the position to be truly centered */
    background-color: rgb(27, 22, 22); /* Button background color */
    color: white; /* Text color */
    padding: 10px 18px; /* Padding inside the link */
    text-align: center; /* Center the text inside the link */
    text-decoration: none; /* No underline */
    font-size: 15px; /* Font size of the text */
    border: none; /* No borders */
    cursor: pointer; /* Cursor appears as pointer */
    border-radius: 35%;
    width: 155px;
    font-family: monospace;
    background: #000; /* Gradient from #001124 to black to #001124 */
    background-color: black;
    border: 2px solid #333232;
    transition: background-color 0.3s;

}

.centered-button-2001:hover {
    background-color: #332c2c; /* Darker background on hover */
}


.centered-button-2002 {
    position: fixed; /* Fixed positioning relative to the viewport */
    top: 65%; /* Center vertically */
    left: 55%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Correct the position to be truly centered */
    background-color: rgb(27, 22, 22); /* Button background color */
    color: white; /* Text color */
    padding: 10px 20px; /* Padding inside the link */
    text-align: center; /* Center the text inside the link */
    text-decoration: none; /* No underline */
    font-size: 16px; /* Font size of the text */
    border: none; /* No borders */
    cursor: pointer; /* Cursor appears as pointer */
    border-radius: 35%;
    width: 155px;
    font-family: monospace;
    background: #000; /* Gradient from #001124 to black to #001124 */
    background-color: black;
    border: 2px solid #333232;
    transition: background-color 0.3s;

}
.centered-button-2003 {
    position: fixed; /* Fixed positioning relative to the viewport */
    top: 65%; /* Center vertically */
    left: 35%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Correct the position to be truly centered */
    background-color: rgb(27, 22, 22); /* Button background color */
    color: white; /* Text color */
    padding: 10px 20px; /* Padding inside the link */
    text-align: center; /* Center the text inside the link */
    text-decoration: none; /* No underline */
    font-size: 16px; /* Font size of the text */
    border: none; /* No borders */
    cursor: pointer; /* Cursor appears as pointer */
    border-radius: 35%;
    width: 155px;
    font-family: monospace;
    background: #000; /* Gradient from #001124 to black to #001124 */
    background-color: black;
    border: 2px solid #333232;
    transition: background-color 0.3s;

}
.centered-button-2004 {
    background-color: rgb(27, 22, 22);
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    font-family: monospace;
    float: right;
    height: 35px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    position: relative;
    transition: background-color 0.3s; /* Smooth transition for color change */
    padding-left: 10px;
    padding-right: 10px;
}
.centered-button-2006 {
    position: fixed; /* Fixed positioning relative to the viewport */
    top: 65%; /* Center vertically */
    left: 65%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Correct the position to be truly centered */
    background-color: rgb(27, 22, 22); /* Button background color */
    color: white; /* Text color */
    padding: 10px 20px; /* Padding inside the link */
    text-align: center; /* Center the text inside the link */
    text-decoration: none; /* No underline */
    font-size: 16px; /* Font size of the text */
    border: none; /* No borders */
    cursor: pointer; /* Cursor appears as pointer */
    border-radius: 35%;
    width: 155px;
    font-family: monospace;
    background: #000; /* Gradient from #001124 to black to #001124 */
    background-color: black;
    border: 2px solid #333232;
    transition: background-color 0.3s;

}

.centered-button-2002:hover {
    background-color: #332c2c; /* Darker background on hover */
}

.centered-button-2003:hover {
    background-color: #332c2c; /* Darker background on hover */
}
.centered-button-2003:hover {
    background-color: #332c2c; /* Darker background on hover */
}



.centered-button-2004::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 25px solid transparent; /* Adjust the size as needed */
    border-bottom: 25px solid transparent; /* Adjust the size as needed */
    border-left: 25px solid rgb(27, 22, 22); /* Adjust the size and color as needed */
    transform: translateX(70%)  translateY(-15%);
    transition: border-left 0.6s;
}

.centered-button-2004:hover::after {
    border-left: 25px solid   #333;
}

.centered-button-2004:hover{
    background-color: #333; /* Darker background on hover */
}

.centered-button-2004 .icon-2004{
    padding-top: 6px;
}


.centered-button-2005 {
    background-color: rgb(27, 22, 22);
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 0;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 18.5px;
    border-top-right-radius: 500px;
    border-bottom-right-radius: 500px;
    width: 168px;
    font-family: monospace;
    float: right; /* This will push the button to the right */
    
    margin-top: 50%;
    margin-bottom: 1px;
    margin-right: 5px;
    
}
.centered-button-2005 .icon-2004{
    padding-top: 6px;
}
.centered-button-2005:hover {
    background-color: #333; /* Darker background on hover */
}



.centered-button-2006:hover {
    background-color: #332c2c; /* Darker background on hover */
}


.quote-next-button-2004{
    background-color: #2b2626;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 0;

    padding-top: 8px;
    padding-bottom: 8px;

    width: 168px;
    font-family: 'FontAwesome';
    float: right; /* This will push the button to the right */

 
}
.quote-next-button-2004:hover {
    background-color: #333; /* Darker background on hover */
}

.quote-next-button-2005{
    background-color: #000;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 0;

    padding-top: 8px;
    padding-bottom: 8px;

 
    font-family: 'FontAwesome';
    float: right; /* This will push the button to the right */

 
}
.quote-next-button-2005:hover {
    background-color: #333; /* Darker background on hover */
}


.ancer-2004 {
    color: rgb(126, 112, 84); /* Make text transparent initially */
    text-decoration: none; /* Remove default underline */
    transition: color 0.3s ease; /* Smooth transition for text color */
  }
  
  /* Pseudo-element for creating shadow effect */
  .ancer-2004::before {
    content: attr(data-text); /* Display text content */
    position: absolute; /* Positioning relative to anchor */
    top: 0; /* Align with anchor */
    left: 0; /* Align with anchor */
    color: rgb(245, 197, 197); /* Text color */
    z-index: -1; /* Behind anchor text */
    opacity: 0; /* Make text transparent initially */
    transition: opacity 0.3s ease; /* Smooth transition for opacity */
  }
  
  /* Hover effect */
  .ancer-2004:hover::before {
    opacity: 1; /* Make text visible on hover */
  }
  
  .ancer-2004:hover {
    color: #ffd700; /* Change text color on hover */
    cursor: pointer; /* Change cursor to pointer on hover */
  }

  @media only screen and (max-width: 1550px) {

    .centered-button-2003 {
        left: 27%; /* Center horizontally */

    }

    .centered-button-2001 {

        left: 43%; /* Center horizontally */

    }
    .centered-button-2002 {
        left: 59%; /* Center horizontally */

    }
    .centered-button-2006 {

        left: 75%; /* Center horizontally */

    }

}


@media only screen and (max-width: 975px) {

    .centered-button-2003 {
        left: 20%; /* Center horizontally */

    }

    .centered-button-2001 {

        left: 40%; /* Center horizontally */

    }
    .centered-button-2002 {
        left: 60%; /* Center horizontally */

    }
    .centered-button-2006 {

        left: 80%; /* Center horizontally */

    }


}



@media only screen and (max-width: 787px) {

    .centered-button-2003 {
        left: 12%; /* Center horizontally */

    }

    .centered-button-2001 {

        left: 37.3%; /* Center horizontally */

    }
    .centered-button-2002 {
        left: 62.63%; /* Center horizontally */

    }
    .centered-button-2006 {

        left: 88%; /* Center horizontally */

    }


}


@media only screen and (max-width: 613px) {

    .centered-button-2003 {
        top: 65%;
        left: 50%; /* Center horizontally */

    }
    .centered-button-2001 {
        top: 73%;
        left: 50%; /* Center horizontally */

    }
    .centered-button-2002 {
        top: 81%;
        left: 50%; /* Center horizontally */

    }

    .centered-button-2006 {

        top: 89%;
        left: 50%; /* Center horizontally */

    }





}

@media only screen and (max-width: 499px) {


    .quote-next-button-2004{
        margin-right: -15px;
      }




}

@media only screen and (max-width: 484px) {


    .quote-next-button-2004{
        padding-left: 0;
        padding-right: 0;
        font-size: 13px;
        width: 120px;
      }




}
@media only screen and (max-width: 452px) {


    .quote-next-button-2004{
        padding-left: 0;
        padding-right: 0;
        font-size: 12px;
        width: 100px;
      }




}

@media only screen and (max-width: 337px) {


    .quote-next-button-2004{

        width: 80px;
      }




}

@media only screen and (max-width: 323px) {


    .quote-next-button-2004{
        font-size: 10px;
        width: 70px;
      }

}



@media only screen and (max-width: 280px) {

    .centered-button-2003 {
        top: 70%;
        left: 50%; /* Center horizontally */

    }
    .centered-button-2001 {
        top: 78%;
        left: 50%; /* Center horizontally */

    }
    .centered-button-2002 {
        top: 86%;
        left: 50%; /* Center horizontally */

    }



    .centered-button-2006 {

        top: 94%;
        left: 50%; /* Center horizontally */

    }




}