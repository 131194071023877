/* Base */
.Aboutme-1 {
    padding: 0;
    margin: 0;
    justify-content: center;
    min-height: 60vh;
    display: grid;
  place-items: center;
  margin-top: 10%;
  }
  .Aboutme-1 h1 {
    color: hsl(0, 0%, 28%);
    font-size: 50px!important;
    font-weight: bold!important;
    font-family: monospace;
    letter-spacing: 7px!important;
    cursor: pointer;
    text-transform: uppercase;
  }
  
 .Aboutme-1 h1 {
    padding: 64px;
    background: linear-gradient(to right, hsl(0, 0%, 30%) 0, hsl(0, 0%, 100%) 10%, hsl(0, 0%, 30%) 20%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
  }
  
  @keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 600px;
    }
    100% {
      background-position: 1000px;
    }
  }