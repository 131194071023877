.bg10 {
    position: absolute;
    top: 0;
    left: 50;
    width: 95vw;
    height: 850px;
    will-change: opacity;
    background-size: 100% 100%;
    border-radius: 10px;
    margin-top: 50px;
  }
  .body4{
    height: 10px;
  }
  @media (max-width: 900px) {

    .bg10 {
      
      

    }
  }

  @media (max-width: 800px) {

    .bg10 {
      width: 75vw;
      height: 480px;
    }
  }


  @media (max-width: 640px) {

    .bg10 {
      width: 65vw;
      height: 340px;
    }
  }



  @media (max-width: 550px) {

    .bg10 {
      height: 280px;
    
    }
  }

  @media (max-width: 450px) {

    .bg10 {

      height: 250px;
    }
  }

  @media (max-width: 400px) {

    .bg10 {

 
      height: 200px;
    }
  }
  

  @media (max-width: 350px) {

    .bg10 {

      height: 180px;
    }
  }

  @media (max-width: 325px) {

    .bg10 {

      height: 150px;
    }
  }