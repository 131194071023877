
  
  .card-content-container {
    display: flex;
    position: relative; /* Add relative positioning */
    flex-direction: row;
    height: 50vh;
  }
  .card-body__header-8001 {
    font-size: x-large;
    margin: 1% 1% 1% 0%;
  }
 
  .card-content-8001 {
    color: white;
    padding: 20px;
    background: #000; /* Gradient from #001124 to black to #001124 */
    width: 95vw;
    height: 120vh;
    border-radius: 1%;
    border: 2px solid #333232;

}

.details-8001 {
    height: 70%;
    width: 40vw;
    margin-right: 1%;
    margin-top: 1%;
    overflow-y: auto;
    line-height: 2; /* Double-spaced text */
    font-size: 1rem; /* Base font size */
    color: #c9d1d9; /* Text color */
}
.details-8001 code{
  font-size: 1rem; /* Base font size */
}
.details-8001 p, ul{
  font-size: 1.2em; /* Adjust heading font size */
}
.details-8001 h1,h2,h3,h4,h5,h6{
  font-size: 1.5em; /* Adjust heading font size */
}

.video-container {
    margin-left: 1%;
    position: relative;
    overflow: hidden;
}
.demo-8001{
    margin: 7% 2% 2% 0%;
    height: 36.5vh;
    width: 46vw;
}
.card-body_2_header-8001{
    
  font-size: x-large;
  margin: -2% 0% 1% 0%;
}

.bst{
  
  width: 90vw;
  height: 65vh;
}
.database{
  margin-top: 70px;
  width: 49vw;
  height: 35.5vh;
}
.card-footer__btn-8001 {
  width: 140px ;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  padding: 3px 30px;
  font-size: 14px;
  margin-bottom: 10px;
  color: white; /* Change button text color to white */
  transition: background-color 200ms ease-out;
  background: #000; /* Gradient from #001124 to black to #001124 */
  background-color: black;
  border: 2px solid #333232;
  border-radius: 25px;
  cursor: pointer;

}
.card-footer__btn-8001:hover {
  color: white;
  background: #332c2c; /* Wet Asphalt */
  text-decoration: none;
}


.c4-image-8001{
  
  object-fit: cover;
  width: 49vw;
  height: auto;
  max-height: 38vh;
  margin-top: 50px;
  
}
@media only screen and (max-width: 1629px) {
  .card-body_2_header-8001{
    
    font-size: x-large;
    margin: 5.28% 1% 1% 0%;
  }
  
  .bst{
    margin-top: 5%;
    width: 51vw;
  }
  .database{
 
    height: 40.5vh;
  }
}
@media only screen and (max-width: 1627px) {
.card-content-container {
    display: flex;
    position: relative; /* Add relative positioning */
    flex-direction: row;
    height: 50vh;
  }
  

  .card-content-8001 {
    
    color: white;
    padding: 20px;
    background: #000; /* Gradient from #001124 to black to #001124 */
    width: 95vw;
    height: 155vh;
    border-radius: 1%;
    border: 2px solid #333232;

}

.details-8001 {
    height: 82%;
    margin-right: 1%;
    margin-top: 1%;
    overflow-y: auto;
    font-size: 1rem; /* Base font size */

}


.video-container {
    margin-left: 1%;
    position: relative;
    overflow: hidden;
}
.demo-8001{
    margin: 9% 2% 2% 0%;
    height: 41vh;
    width: 50vw;
}
.card-body_2_header-8001{
    
  font-size: x-large;
  margin: 1% 0% 0% 0%;
}

.bst{
  width: 92vw;
  height: 80vh;
}


.c4-image-8001{
  
  object-fit: cover;
  width: 51vw;
  height: auto;
  max-height: 38vh;
  margin-top: 70px;
  
}
  
}


@media only screen and (max-width: 1095px) {
  .demo-8001{
    margin: 12% 2% 2% 0%;
    height: 41vh;
    width: 50vw;
}
.details-8001 {
  height: 80%;
  width: 45vw;
  margin-right: 1%;
  margin-top: 1%;
  overflow-y: auto;
  font-size: 1rem; /* Base font size */
}
}

@media only screen and (max-width: 847px) {
  .demo-8001{
    margin: 2% 2% 2% 0%;
    height: 41vh;
    width: 80vw;
}
.details-8001 {
  height: 70%;
  width: 80vw;
  margin-right: 1%;
  margin-top: 1%;
  overflow-y: auto;
  font-size: 1rem; /* Base font size */
}

.card-content-container {
  display: flex;
  position: relative; /* Add relative positioning */
  flex-direction: row;
  height: 50vh;
}


.card-content-8001 {
  color: white;
  padding: 20px;
  background: #000; /* Gradient from #001124 to black to #001124 */
  width: 90vw;
  height: 100vh;
  border-radius: 1%;
  border: 2px solid #333232;

}



.video-container {
  margin-left: 1%;
  position: relative;
  overflow: hidden;
}

.card-body_2_header-8001{
  
font-size: x-large;
margin: -2% 0% 1% 0%;
}

.bst{
margin-left: -2%;
margin-top: -2%;
width: 85vw;
height: 47vh;
}
.card-footer__btn-8001 {
  width: 30px ;
  padding-right: 40px;
}


}


@media only screen and (max-width: 523px)
{
  .card-body__header-8001 {
    margin-top: 12%;
    
  }
  .bst{
    margin-left: -2%;
    margin-top: 8%;
    width: 82vw;
    height: 40vh;
    }
    
}
@media only screen and (max-width: 442px)
{
  .details-8001 {
    height: 280px;
    width: 80vw;
    margin-right: 1%;
    margin-top: 1%;
    overflow-y: auto;
    font-size: 95%; /* Base font size */
  }
  .details-8001 code{
    font-size: 95%; /* Base font size */
  }
}

@media only screen and (max-width: 390px) {
  .details-8001 {
    height: 330px;

  }
  .demo-8001{
    margin: 20% 2% 2% 0%;
}
.card-content-8001 {
  color: white;
  padding: 20px;
  background: #000; /* Gradient from #001124 to black to #001124 */
  width: 90vw;
  height: 110vh;
  border-radius: 1%;
  border: 2px solid #333232;

}
.bst{
  margin-left: -5%;
  margin-top: 8%;
  width: 86vw;
  height: 52vh;
  }
}
@media only screen and (max-width: 280px) {

  .card-content-container {
    display: flex;
    position: relative; /* Add relative positioning */
    flex-direction: row;
    height: 50vh;
  }
  
  
  .card-content-8001 {
    color: white;
    padding: 20px;
    background: #000; /* Gradient from #001124 to black to #001124 */
    width: 80vw;
    height: 120vh;
    border-radius: 1%;
    border: 2px solid #333232;
  
  }

  .demo-8001{
    margin: 60% 2% 2% 0%;
    height: 41vh;
    width: 65vw;
}
.details-8001 {
  width: 65vw;
  margin-right: 1%;
  margin-top: 1%;
  overflow-y: auto;
  font-size: 1rem; /* Base font size */

}
.bst{
  margin-left: -12%;
  margin-top: 50%;
  width: 75vw;
  height: 52vh;
  }

}
/*
@media only screen and (max-width: 1339px) {

  .details-8001 {
    height: 37%;
    margin-right: 1%;
    margin-top: 1%;
    overflow-y: auto;
    width: 38vw;
    flex-basis: 35%;
    font-size: large;
}
.demo-8001{
  margin: 1% 2% 2% 0%;
  width: 38vw;
  height: 40vh;
}
  .card-body_2_header-8001{
    
    font-size: x-large;
    margin: 6.4% 1% 1% 0%;
  }
  
  .bst{
    margin-top: 5%;
    width: 56vw;
  }
}

@media only screen and (max-width: 1133px) {

  .details-8001 {
    height: 37%;
    margin-right: 1%;
    margin-top: 1%;
    overflow-y: auto;
    width: 38vw;
    flex-basis: 35%; 
    font-size: large;
}
.demo-8001{
  margin: 1% 2% 2% 0%;
  width: 38vw;
  height: 40vh;
}
  .card-body_2_header-8001{
    
    font-size: x-large;
    margin: 7.4% 1% 1% 0%;
  }
  
  .bst{
    margin-top: 5%;
    width: 56vw;
  }
}

@media only screen and (max-width: 1150px) {

  .details-8001 {
    height: 37%;
    margin-right: 1%;
    margin-top: 1%;
    overflow-y: auto;
    width: 38vw;
    flex-basis: 35%; 
    font-size: large;
}
.demo-8001{
  margin: 1% 2% 2% 0%;
  width: 38vw;
  height: 40vh;
}
  .card-body_2_header-8001{
    
    font-size: x-large;
    margin: 7.4% 1% 1% 0%;
  }
  
  .bst{
    margin-top: 5%;
    width: 56vw;
  }
}


*/

@media only screen and (max-height: 843px) {

  .details-8001 {
    height:  300px;
}
.demo-8001{
 
  height: 320px;
  margin-top: 50px;
}
}
@media only screen and (max-height: 811px) {

  .details-8001 {
    height:  280px;
}
.demo-8001{
 
  height: 320px;
  margin-top: 50px;
}
}
@media only screen and (max-height: 794px) {

  .details-8001 {
    height:  260px;
}
.demo-8001{
 
  height: 320px;
  margin-top: 40px;
}
}
@media only screen and (max-height: 777px) {

  .details-8001 {
    height:  250px;
}
.demo-8001{
 
  height: 320px;
  margin-top: 30px;
}
}
@media only screen and (max-height: 750px) {

  .details-8001 {
    height:  240px;
}
.demo-8001{
 
  height: 300px;
  margin-top: 35px;
}
}
@media only screen and (max-height: 726px) {

  .details-8001 {
    height:  230px;
}
.demo-8001{
 
  height: 300px;
  margin-top: 30px;
}
}

@media only screen and (max-height: 721px) {

  .details-8001 {
    height:  230px;
}
.demo-8001{
 
  height: 280px;
  margin-top: 35px;
}
}
@media only screen and (max-height: 707px) {

  .details-8001 {
    height:  230px;
}
.demo-8001{
 
  height: 250px;
  margin-top: 50px;
}
}

@media only screen and (max-height: 674px) {

  .details-8001 {
    height:  200px;
}
.demo-8001{
 
  height: 220px;
  margin-top: 60px;
}
}
@media only screen and (max-height: 644px) {

  .details-8001 {
    height:  200px;
}
}
@media only screen and (max-height: 613px) {

  .details-8001 {
    height:  190px;
}
.demo-8001{
 
  height: 210px;
  margin-top: 50px;
}
}
@media only screen and (max-height: 593px) {

  .details-8001 {
    height:  180px;
}
}
@media only screen and (max-height: 576px) {

  .details-8001 {
    height:  160px;
}
}

  
  .Languages_used-8001 {
    font-size: large;
    margin: 3% 1% 3% 0%;
    margin-bottom: 1%;
  }
  

  .card-footer-8001 {
    position: absolute; 
    top: 0; 
    right: 0; 
    margin-top: 20px; 
    margin-right: 20px; 
  }

