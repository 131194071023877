.container16 {
  display: flex;
 
  margin-bottom: 6%;
  

  
}
.para2{
margin-top: 200px;
}
.para3{
margin-top: 100px;
}.column16 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  margin-top: 3%;
  border: 2px solid rgb(39, 33, 33);
  background-color: transparent;
  border-radius: 10px;
  width: 95vw; /* Adjust to 95% to make more responsive */
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.github-stuff16 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; /* Take full width of the parent container */
  margin: 10px;
  max-width: 20%; /* Adjust max-width to be percentage for responsiveness */
}

.github-stuff16 img {
  width: 100%; /* Make image width responsive */
  height: auto; /* Maintain aspect ratio */
}


@media (max-width: 800px) {
  .column16 {

    width: 75vw; /* Adjust to 95% to make more responsive */
    
  }
  .github-stuff16{
    margin: 12px;
  }

  .github-stuff16 {
    max-width: 45%; /* Increase max-width for smaller devices */
  }
}
@media (max-width: 640px) {
  .column16{
    width: 65vw; 
  }
  .column16 {

    margin-top: -35%;
    
  }
  .github-stuff16{
    margin: 12px;
  }

  .github-stuff16 {
    max-width: 45%; /* Increase max-width for smaller devices */
  }
}
@media (max-width: 480px) {
  .column16 {

    width: 65vw; /* Adjust to 95% to make more responsive */
    
  }
  .github-stuff16 {
    max-width: 65%; /* Use almost full width on very small devices */
    margin: 5px; /* Reduce margin to save space */
  }
}
