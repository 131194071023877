.background-container {
    width: 100%;
    height: 100%;
    color:hsl(0, 0%, 30%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  


  .container {
    max-width: 800px;
    width: 50vw;
    margin-top: 100px;
  }
  