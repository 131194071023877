html, body, .container {
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #C0DEED 50%, #578cb9 100%);
}

.container {
  height: 1000px;
}

.cloud {
  background: url('./Images/clouds2.png') repeat-x;
  background-color: #b7d8e9;
  background-size: cover;
  background-position: center;
  height: 100%;
  animation: cloudani 100s linear infinite;
}

@keyframes cloudani {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}
