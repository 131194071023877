body {
    margin: 0;
    padding: 0;
  }
  
  .starScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
  }
  
  .main {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 1.5px;
    width: 1.5px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: -24vw -44vh 2px 2px #fff, 38vw -4vh 0px 0px #fff, -20vw -48vh 1px 2px #fff,
      -30vw -15vh 1px 1px #fff, -31vw 19vh 1px 1px #fff, -1vw -30vh 0px 0px #fff, 17vw -10vh 0px 0px #fff,
      30vw 20vh 0px 0px #fff, -20vw 20vh 1px 0px #fff, -39vw 38vh 3px 1px #fff, -42vw -11vh 0px 3px #fff,
      12vw 15vh 3px 3px #fff, 42vw 6vh 3px 2px #fff, -8vw 9vh 0px 2px #fff, 34vw -38vh 1px 0px #fff,
      -17vw 45vh 3px 1px #fff, 22vw -36vh 3px 2px #fff, -42vw 1vh 1px 0px #fff;
    animation: zoom 15s alternate infinite;
  }
  
  @keyframes zoom {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.5);
    }
  }
  