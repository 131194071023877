@media (min-width: 1650px) {

body {
    padding: 50px;
  }
  
  .switch {
    display: inline-block;
    position: fixed;
    left: 75%;
    top: 0%;
    size: 0;
  }
  
  .switch__input {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }
  
  .switch__label {
    position: relative;
    display: inline-block;
    width: 100px; /* Adjusted width */
    height: 45px; /* Adjusted height */
    background-color: #2B2B2B;
    border: 3px solid #5B5B5B;
    border-radius: 9999px;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(.46, .03, .52, .96);
  }
  
  .switch__indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateX(-60%); /* Adjusted translateX */
    display: block;
    width: 31px; /* Adjusted width */
    height: 31px; /* Adjusted height */
    background-color: #7B7B7B;
    border-radius: 9999px;
    box-shadow: 6px 0px 0 0 rgba(#000000, 0.2) inset;
  
    &::before,
    &::after {
      position: absolute;
      content: '';
      display: block;
      background-color: #FFFFFF;
      border-radius: 9999px;
    }
  
    &::before {
      top: 4px;
      left: 4px;
      width: 6px;
      height: 6px;
      background-color: #FFFFFF;
      opacity: 0.6;
    }
  
    &::after {
      bottom: 6px;
      right: 4px;
      width: 10px;
      height: 10px;
      background-color: #FFFFFF;
      opacity: 0.8;
    }
  }
  
  .switch__decoration {
    position: absolute;
    top: 65%;
    left: 50%;
    display: block;
    width: 5px; /* Adjusted width */
    height: 5px; /* Adjusted height */
    background-color: #FFFFFF;
    border-radius: 9999px;
    animation: twinkle 0.8s infinite -0.6s;
  
    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      width: 5px; /* Adjusted width */
      height: 5px; /* Adjusted height */
      background-color: #FFFFFF;
      border-radius: 9999px;
    }
  
    &::before {
      top: -12px;
      left: 6px;
      opacity: 1;
      animation: twinkle 0.6s infinite;
    }
  
    &::after {
      top: -5px;
      left: 18px;
      animation: twinkle 0.6s infinite -0.2s;
    }
  }
  
  @keyframes twinkle {
    50% {
      opacity: 0.2;
    }
  }
  
  .switch__indicator {
    &,
    &::before,
    &::after {
      transition: all 0.4s cubic-bezier(.46, .03, .52, .96);
    }
  }
  
  .switch__input:checked + .switch__label {
    background-color: #8FB5F5;
    border-color: #347CF8;
  
    .switch__indicator {
      background-color: #ECD21F;
      box-shadow: none;
      transform: translate(-50%, -50%) translateX(60%); /* Adjusted translateX */
  
      &::before,
      &::after {
        display: none;
      }
    }
  
    .switch__decoration {
      top: 50%;
      transform: translate(0%, -50%);
      animation: cloud 8s linear infinite;
  
      width: 13px; /* Adjusted width */
      height: 13px; /* Adjusted height */
  
      &::before {
        width: 7px; /* Adjusted width */
        height: 7px; /* Adjusted height */
        top: auto;
        bottom: 0;
        left: -4px;
        animation: none;
      }
  
      &::after {
        width: 10px; /* Adjusted width */
        height: 10px; /* Adjusted height */
        top: auto;
        bottom: 0;
        left: 8px;
        animation: none;
      }
  
      &,
      &::before,
      &::after {
        border-radius: 9999px 9999px 0 0;
      }
  
      &::after {
        border-bottom-right-radius: 9999px;
      }
    }
  }
  
  @keyframes cloud {
    0% {
      transform: translate(0%, -50%);
    }
    50% {
      transform: translate(-50%, -50%);
    }
    100% {
      transform: translate(0%, -50%);
    }
  }
}



@media (min-width: 768px) and (max-width: 1650px){
  body {
    padding: 50px;
  }
  
  .switch {
    display: inline-block;
    position: fixed;
    left: 85%;
    top: 85%;
    size: 0;
  }
  
  .switch__input {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }
  
  .switch__label {
    position: relative;
    display: inline-block;
    width: 100px; /* Adjusted width */
    height: 45px; /* Adjusted height */
    background-color: #2B2B2B;
    border: 3px solid #5B5B5B;
    border-radius: 9999px;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(.46, .03, .52, .96);
  }
  
  .switch__indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateX(-60%); /* Adjusted translateX */
    display: block;
    width: 31px; /* Adjusted width */
    height: 31px; /* Adjusted height */
    background-color: #7B7B7B;
    border-radius: 9999px;
    box-shadow: 6px 0px 0 0 rgba(#000000, 0.2) inset;
  
    &::before,
    &::after {
      position: absolute;
      content: '';
      display: block;
      background-color: #FFFFFF;
      border-radius: 9999px;
    }
  
    &::before {
      top: 4px;
      left: 4px;
      width: 6px;
      height: 6px;
      background-color: #FFFFFF;
      opacity: 0.6;
    }
  
    &::after {
      bottom: 6px;
      right: 4px;
      width: 10px;
      height: 10px;
      background-color: #FFFFFF;
      opacity: 0.8;
    }
  }
  
  .switch__decoration {
    position: absolute;
    top: 65%;
    left: 50%;
    display: block;
    width: 5px; /* Adjusted width */
    height: 5px; /* Adjusted height */
    background-color: #FFFFFF;
    border-radius: 9999px;
    animation: twinkle 0.8s infinite -0.6s;
  
    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      width: 5px; /* Adjusted width */
      height: 5px; /* Adjusted height */
      background-color: #FFFFFF;
      border-radius: 9999px;
    }
  
    &::before {
      top: -12px;
      left: 6px;
      opacity: 1;
      animation: twinkle 0.6s infinite;
    }
  
    &::after {
      top: -5px;
      left: 18px;
      animation: twinkle 0.6s infinite -0.2s;
    }
  }
  
  @keyframes twinkle {
    50% {
      opacity: 0.2;
    }
  }
  
  .switch__indicator {
    &,
    &::before,
    &::after {
      transition: all 0.4s cubic-bezier(.46, .03, .52, .96);
    }
  }
  
  .switch__input:checked + .switch__label {
    background-color: #8FB5F5;
    border-color: #347CF8;
  
    .switch__indicator {
      background-color: #ECD21F;
      box-shadow: none;
      transform: translate(-50%, -50%) translateX(60%); /* Adjusted translateX */
  
      &::before,
      &::after {
        display: none;
      }
    }
  
    .switch__decoration {
      top: 50%;
      transform: translate(0%, -50%);
      animation: cloud 8s linear infinite;
  
      width: 13px; /* Adjusted width */
      height: 13px; /* Adjusted height */
  
      &::before {
        width: 7px; /* Adjusted width */
        height: 7px; /* Adjusted height */
        top: auto;
        bottom: 0;
        left: -4px;
        animation: none;
      }
  
      &::after {
        width: 10px; /* Adjusted width */
        height: 10px; /* Adjusted height */
        top: auto;
        bottom: 0;
        left: 8px;
        animation: none;
      }
  
      &,
      &::before,
      &::after {
        border-radius: 9999px 9999px 0 0;
      }
  
      &::after {
        border-bottom-right-radius: 9999px;
      }
    }
  }
  
  @keyframes cloud {
    0% {
      transform: translate(0%, -50%);
    }
    50% {
      transform: translate(-50%, -50%);
    }
    100% {
      transform: translate(0%, -50%);
    }
  }
}

@media (max-width: 768px)  {
  body {
    padding: 50px;
  }
  
  .switch {
    display: inline-block;
    position: fixed;
    left: 65%;
    top: 85%;
    size: 0;
  }
  
  .switch__input {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }
  
  .switch__label {
    position: relative;
    display: inline-block;
    width: 100px; /* Adjusted width */
    height: 45px; /* Adjusted height */
    background-color: #2B2B2B;
    border: 3px solid #5B5B5B;
    border-radius: 9999px;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(.46, .03, .52, .96);
  }
  
  .switch__indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateX(-60%); /* Adjusted translateX */
    display: block;
    width: 31px; /* Adjusted width */
    height: 31px; /* Adjusted height */
    background-color: #7B7B7B;
    border-radius: 9999px;
    box-shadow: 6px 0px 0 0 rgba(#000000, 0.2) inset;
  
    &::before,
    &::after {
      position: absolute;
      content: '';
      display: block;
      background-color: #FFFFFF;
      border-radius: 9999px;
    }
  
    &::before {
      top: 4px;
      left: 4px;
      width: 6px;
      height: 6px;
      background-color: #FFFFFF;
      opacity: 0.6;
    }
  
    &::after {
      bottom: 6px;
      right: 4px;
      width: 10px;
      height: 10px;
      background-color: #FFFFFF;
      opacity: 0.8;
    }
  }
  
  .switch__decoration {
    position: absolute;
    top: 65%;
    left: 50%;
    display: block;
    width: 5px; /* Adjusted width */
    height: 5px; /* Adjusted height */
    background-color: #FFFFFF;
    border-radius: 9999px;
    animation: twinkle 0.8s infinite -0.6s;
  
    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      width: 5px; /* Adjusted width */
      height: 5px; /* Adjusted height */
      background-color: #FFFFFF;
      border-radius: 9999px;
    }
  
    &::before {
      top: -12px;
      left: 6px;
      opacity: 1;
      animation: twinkle 0.6s infinite;
    }
  
    &::after {
      top: -5px;
      left: 18px;
      animation: twinkle 0.6s infinite -0.2s;
    }
  }
  
  @keyframes twinkle {
    50% {
      opacity: 0.2;
    }
  }
  
  .switch__indicator {
    &,
    &::before,
    &::after {
      transition: all 0.4s cubic-bezier(.46, .03, .52, .96);
    }
  }
  
  .switch__input:checked + .switch__label {
    background-color: #8FB5F5;
    border-color: #347CF8;
  
    .switch__indicator {
      background-color: #ECD21F;
      box-shadow: none;
      transform: translate(-50%, -50%) translateX(60%); /* Adjusted translateX */
  
      &::before,
      &::after {
        display: none;
      }
    }
  
    .switch__decoration {
      top: 50%;
      transform: translate(0%, -50%);
      animation: cloud 8s linear infinite;
  
      width: 13px; /* Adjusted width */
      height: 13px; /* Adjusted height */
  
      &::before {
        width: 7px; /* Adjusted width */
        height: 7px; /* Adjusted height */
        top: auto;
        bottom: 0;
        left: -4px;
        animation: none;
      }
  
      &::after {
        width: 10px; /* Adjusted width */
        height: 10px; /* Adjusted height */
        top: auto;
        bottom: 0;
        left: 8px;
        animation: none;
      }
  
      &,
      &::before,
      &::after {
        border-radius: 9999px 9999px 0 0;
      }
  
      &::after {
        border-bottom-right-radius: 9999px;
      }
    }
  }
  
  @keyframes cloud {
    0% {
      transform: translate(0%, -50%);
    }
    50% {
      transform: translate(-50%, -50%);
    }
    100% {
      transform: translate(0%, -50%);
    }
  }
}