.container-600 {
  display: flex;
  width: 100%; /* Adjusted width */
  padding: 4% 2%;
  box-sizing: border-box;
  height: 70vh;
  overflow: hidden; /* Ensure hidden overflow to create sliding effect */
}
@media (max-width: 1300px) {
  .container-600 {
    display: flex;
  width: 100%; /* Adjusted width */
  padding: 4% 2%;
  box-sizing: border-box;
  height: 90vh;
  overflow: hidden; /* Ensure hidden overflow to create sliding effect */
  margin-top: 10px;
  }

 
}


 

.box-600 {
  position: relative;
  flex: 1;
  overflow: hidden;
  transition: .5s;
  margin: 0 0.6%;
  box-shadow: 0 20px 30px rgba(0, 0, 0, .9);
  line-height: 0;
  animation: slide 10s linear infinite; /* Animation for sliding */
}

.box-600 > img {
  width: 100%;
  height: calc(100% - 15vh);
  object-fit: cover;
  transition: .5s;
}

.box-600 > span {
  font-size: 1.8vh;
  display: block;
  text-align: center;
  height: 10vh;
  line-height: 1.5;
}

.box-600:hover {
  flex: 1 1 50%;
  background-image: url(''); /* Reset background image */
  animation-play-state: paused; /* Pause animation on hover */
}

.box-600:hover > img {
  width: 100%;
  height: 100%;
}

.box-600 > video {
  width: 100%;
  height: calc(100% ); /* Adjusted height */
  object-fit: cover;
  transition: .5s;
}

